@import '../../../styles/globals';

* {
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .myInnovations-container {
    margin-bottom: 5%;
    width: 100%;
    .innovations {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: black;
      background-color: white;
      border-radius: 12px;
      padding: 5%;
      width: 100%;
      height: 100%;
      position: relative;

      h4 {
        color: black;
        font-size:1.6rem;
        font-weight: bolder;
        margin-right: 2rem;
      }
      span {
        text-align: center;
       // font-size: 2vw;
      font-size: 1.2rem;
      }
      .date{
        font-size: 0.9rem;
      }
      img {
        // height: 40vh;
        // width: 60vw;
        height: 15rem;
    width: 60rem;
        max-width: 100%;
        max-height: 100%;
        border-radius: 6vh;
      }
      p {
        // color: white;
        border-radius: 10px;
        background-color: grey;
        // padding-left: 15%;
        // padding-right: 15%;
        padding: 3%;
        max-width: 50em;
    max-height: 6em;
        // max-width: 50vw;
        // max-height: 4.5vw;
        min-width: 100%;
        margin-top: 5%;
        font-size: 1rem;
        // font-weight: bold;
        overflow-y: auto;
        height: 85px;
        
      }
      button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 3%;
        right: 4%;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #7c57da;
        font-size: 2.5rem;
        color: white;
      }

      .button-cont {
        background-image: url('./button.png');
      }

      button:active {
        box-shadow: 0 5px #7c57da;
        transform: translateY(4px);
      }
      button:focus {
        outline: none;
      }
    }
  }
}

@media (min-width: 768px) {
  .myInnovations-container {
    margin-bottom: 10%;
    .innovations {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: black;
      background-color: white;
      border-radius: 3vh;
      padding: 5%;
      max-width: 50vw;
      // max-height: 90vh;
      position: relative;

      h4 {
        color: black;
        font-size: 2.4rem;
        font-weight: bolder;
      }
      span {
        text-align: center;
        font-size: 1.2rem;
      }
      .date{
        font-size: 0.9rem;
      }
      img {
        // height: 40vh;
        // width: 60vw;
        height: 24rem;
    width: 60rem;
        max-width: 100%;
        max-height: 100%;
        border-radius: 6vh;
      }
      p {
        border-radius: 10px;
        background-color: grey;
        padding: 3%;
        // padding-left: 15%;
        // padding-right: 15%;
        // max-width: 50vw;
        // max-height: 4.5vw;
        max-width: 50em;
    // max-height: 6em;
        min-width: 100%;
        text-align: start;
        font-size: 1.1rem;
        overflow-y: auto;
        height: 120px;
       
      }
      button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 4%;
        right: 5%;
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        background-color: #7c57da;
        font-size: 3rem;
        color: white;
      }

      .button-cont {
        background-image: url('./button.png');
      }

      button:active {
        box-shadow: 0 5px #7c57da;
        transform: translateY(4px);
      }
      button:focus {
        outline: none;
      }
    }
    .content-image{
      background-color: $color-black;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }
  }
}
