@import './globals';

canvas {
  width: 95% !important;
  height: 95% !important;
  margin: 2.5% auto !important;
  max-width: 800px;
  outline: 1px solid black;
}

.resume-container {
  .fa-arrow-to-bottom {
    margin-right: 6px;
  }
}