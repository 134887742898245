.company-details-businessvalue {
    width: 100%;
    display: flex;
    flex-direction: column;
    .form-label {
      color: black;
    }
    .form-control {
      width: 100%;
      margin-bottom: 1rem;
    }
}