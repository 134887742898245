@import '../../styles/globals';

// * { outline: 1px dashed red; }

.gallery-builder-dashboard-form {
  .action-btn {
    padding: 0px 20px !important;
    min-width: unset !important;
    height: 26px !important;
    font-weight: 400;
    font-size: 0.875rem;
  }

  .x-btn {
    background-color: $color-red;
    color: $color-white;
    border: none;
    width: 26px;
    border-radius: 30px;
    font-weight: 600;
  }

  .x-btn:active {
    transition: 0.15s ease-in-out;
    box-shadow: none !important;
  }

  .x-btn:hover {
    background-color: lighten($color-red, 10%);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
    transform: scale(1.01);
  }
}

.gallery-builder-form {
  select {
    border-color: $color-secondary-text;
  }

  select > option:active {
    background-color: $color-purple !important;
  }
  .input-group-text {
    padding: unset;
    background-color: $color-purple;
    color: $color-white;
  }

  .add-btn {
    border: unset;
    background-color: transparent;
    box-shadow: unset;
    padding: 0px 10px 0px 15px;
    border-radius: unset;
    color: white;
    height: 100%;
  }

  .delete-user-btn {
    color: $color-red;
    font-size: 1rem;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
}

.profiles-form {
  .row:nth-child(even) {
    background-color: darken(whitesmoke, 10%);
  }
}

.fa-chevron-right,
.fa-chevron-left {
  font-size: 2rem;
  color: $color-purple;
  // padding-top: 400px !important;
}

.fa-chevron-right {
  padding-left: 20px !important;
}

.fa-chevron-left {
  padding-right: 20px !important;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1 !important;
}

.gallery-carousel {
  .singleCard {
    width: 80%;
    margin: auto;
    .attributeImage {
      .content-image {
        background-color: $color-black;
      }
    }
    .attributeHeader {
      position: relative;
      -webkit-transform: translateY(10%);
      -ms-transform: translateY(-10%);
      transform: translateY(10%);
    }
    .attributeText {
      padding: 1rem 0;
      min-width: 0;
      .description-text {
        // width: 80%;
      }
    }
  }
}

.managerCard {
  // background-color: rgba(183, 0, 255, 0.199);
}

.currentProfile {
  border: 2px solid $color-purple;
}

// .game-carousel {
//   min-width: inherit;
//   .carousel-control-prev,
//   .carousel-control-next {
//     width: 20px;
//   }
// }

// .gameCarouselItem {
//   display: flex;
//   .col {
//     margin: 0px 30px;
//   }
// }

.gameAttributes{
  overflow-x: scroll;
  flex-wrap: unset;
  .col{
    margin: 0 5px;
  }
}