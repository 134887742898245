// Font
$size-default: 1rem;

// Base Colors
$color-white: #fff;
$color-purple: #6A44B6;
$color-green: green;
$color-red: red;
$color-black: black;

// Variants
$bkg-color: #F7F7F7;
$bkg-color-darker: darken($color-white, 10%);
$border-color: darken($color-white, 15%);
$color-light-gray: darken($color-white, 20%);
$color-disabled-text: darken($color-white, 25%);
$color-tertiary-text: darken($color-white, 35%);
$color-secondary-text: darken($color-white, 50%);
$color-action-button: darken($color-white, 80%);
$color-lightest-purple: lighten($color-purple, 20%);
$color-disabled-purple: lighten($color-purple, 50%);
$color-light-purple: lighten($color-purple, 2.5%);
$color-dark-purple: darken($color-purple, 15%);
$color-darkest-purple: darken($color-purple, 20%);
$gray-border: 1px solid #d0d0d0;
$color-blue-dark: #2266C2;

$standard-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5) !important;
