.adminQuestionPage {
  width: 83%;
  height: 100%;
  background-color: #151c37;
  display: flex;
  flex-direction: column;
  padding: 2%;
  h1 {
    color: white;
    font-size: 3vw;
  }
}

.question-input {
  width: 100%;
  margin-bottom: 2%;
}
a {
 font-size: large;
}
a:hover {
  cursor: pointer;

}

.company {
  border-radius: 10px;
  margin-top: 20px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
  color: white;
  background-color: #47317d;
  h3 {
    padding: 2%;
  }

  section {
    // display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: black;
    font-size: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 40px;
    width: 100%;
    object-fit: contain;
    // padding-top: 112px;
  }
}


.title {
  font-size: 2.7em;
  text-shadow: black 2px 2px 5px;
  span {
    padding: 5px;
  }
}
@media (max-width: 600px) {
  .title {
      font-size: 2.4em;
  }
  }


.buttonWrapper {
  margin: 20px;
}
.button {
  height: 48px;
  background-color: #47317D;
  color: white;
  // font-weight: bold;
  // font-size: 18px;
  // line-height: 23px;
  font-family: 'Montserrat', sans-serif;
  padding-right: 30px;
  padding-left: 30px;
  border-width: 0;

}

@media (min-width: 992px)
{
.adminQuestionPage {
    width: 83%;
}
}

@media (min-width: 768px)
{
.adminQuestionPage {
    width: 83%;
}
}


@media (max-width: 600px)
{
.adminQuestionPage {
    width: 100%;
}
}

.bottom-button {
 // background-color: #272d46;
  }
  
  .btn-right-corner-innovation{
  justify-content: flex-end;
      display: flex;
      padding-right:15px !important;
    }

    
@media (max-width: 600px)
{
.btn-right-corner-innovation {
    justify-content: unset; 
     display: unset; 
     padding-right: 15px !important;
}
}

.summary-main-section{
  background-color: #272d46;
}

.card-text{
  font-weight: 600;
}

.questionSelectMenu input:focus{
  box-shadow: none;
}

.questionSelectMenu .ant-select-selector:hover, .ant-select-selector:focus-within {
  border-color: #47317D !important;
  box-shadow: 0 0 0 2px rgba(71, 49, 125, 0.2) !important;
}