@import '../../styles/globals';

@media (max-width: 768px) {
  .insightsPage {
    width: 100%;
    background-color: #151c37;
    display: flex;
    flex-direction: column;
    h1 {
      color: white;
      // font-size: 3vw;
      font-size: 1.7rem;
    }
  }
}

@media (min-width: 768px) {
  .insightsPage {
    background-color: #151c37;
    display: flex;
    flex-direction: column;

    width: 83%;
    h1 {
      color: white;
      // font-size: 3vw;
          font-size: 2.7rem;
    }
  }
}

.insightsBox {
  padding-left: 5%;
  padding-right: 5%;
}

@media (max-width: 768px) {
  .vibe-innovate {
    display: grid;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .vibe-innovate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}



.wrapper {
  width: 100%;
}

.profile-box {
  color: white;
  background-color: #151c37;
  display: grid;
  grid-template-columns: 1fr 2.3fr;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;

.profileNav{
  position: relative;
  top: -12%;
  right: -74%;
  span {
    margin: 5px;
  }
}
svg {
  width: 20px;
  height: 20px;
}
b{
  font-size: 1.5vw;
}
button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: white;
  text-align: center;
}
}

.picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;


  .profileImage {
    width: 20vw;
    height: 20vw;
    padding: 5px;
    border-radius: 100%;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
    object-fit: cover;
  }

  .edit{
    width:auto;

    margin-top: 1em;
    img:hover{
      transform: scale(1.2);
    }
    
  }
 

}
.infoWrapper{
  display: column;
  margin-top: 6%;
  
}
.userName{
  display: flex;
  align-items: center;

  strong {
    font-size: 2.5vw;
    
  }
  button {
   
 
    margin-left: 10px;
    font-size: 1vw;
   
    background-color: #7c57da;
    color: white;
  
    font-family: 'Montserrat', sans-serif;
  }
  button:hover{
    transform: scale(1.2)
  }
  
}
.name-location{
  button {
   
    
    font-size: 1vw;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
    background-color: #47317D;
    color: white;
  
    font-family: 'Montserrat', sans-serif;
  }
  button:hover{
    transform: scale(1.2)
  }
}
.colorModal{

  form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 
   label{
  
    border-radius: 100%;
    margin: 5px;

   }
   .colorInputs{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    margin-bottom: 10px;
    width:fit-content;
  
    
    input[type=radio] {
      display: none;
    }
    
    img:hover {
      opacity:0.6;
      cursor: pointer;
    }
    
    img:active {
      opacity:0.4;
      cursor: pointer;
    }
    
    input[type=radio]:checked + img {
      border: 5px solid;
      border-radius: 100%;
   
    }
   }
   button {
    
    width: 50%;
    font-size: 20px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
    background-color: #47317D;
    color: white;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
  }
  }
}
.user-info {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  font-size: 2vw;
  strong {
    font-size: 2.5vw;
    
  }
  nav {
    font-size: 1.5vw;
  }
  svg {
    width: 1vw;
    height: 1vw;
  }
}

.buttonBlock {
  display: flex;
  justify-content: center;

}
@media (max-width: 768px) {
  .picture{
    margin: auto;
  }
}

.myProfile {
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('personal_summary.png');
  background-repeat: round;
  // background-size: 100%;
  section {
    background: white;
    color: black;
    font-size: 1.8rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 3%;
    text-align: left;
    p{
      text-align: left;
    }
    strong{
      background-color: #D2F3E0;
      border-radius: 5px;
      padding: 3px;
    }
    b{
      background-color: #FCF2CB;
      border-radius: 5px;
      padding: 3px;
    }
  }
}

@media (max-width: 768px) {
  .myProfile {
   
    section {
     
      font-size: 1rem;
    }
  }
}

.footerNav {
  margin-top: 20px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  span {
    text-align: center;
  }
  h3 {
    color: white;
  }
  svg {
    margin-bottom: 20px;
    max-width: 3vw;
    min-width: 2vw;
    height: auto;
  }
  button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: white;
    text-align: center;
  }
}

.sixAttributes {
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
  color: white;
  background-color: #47317d;
  h3 {
    padding: 2%;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: black;
    font-size: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    width: 100%;
  }
}
.title {
  font-size: 3.7vw;
  text-shadow: black 2px 2px 5px;
  span {
    padding: 5px;
  }
}

.innovations {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: white;
  border-radius: 10px;
  padding: 5%;
  max-width: 410px;

  h4 {
    color: black;
    font-size: 3vw;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
  }
  p {
    border-radius: 10px;
    background-color: grey;
    padding: 10%;
    max-width: 100%;
    min-width: 100%;
    margin-top: 5%;
    margin-bottom: 3%;
    font-size: 1vw;
  }
  button {
    width: 20px;
    height: 20px;
    color: white;
  }
  button:active {
    box-shadow: 0 5px #7c57da;
    transform: translateY(4px);
  }
  button:focus {
    outline: none;
  }
}
.customLinks{
  display: flex;
  justify-content: space-between;
  button{
   
    color: white;
    background-color: #7c57da;
    margin-right: 15vw;
    font-size: 1.2vw;
  }

  button:hover {
    transform: scale(1.2);
  }
 
  a{
    margin: 5px;
  }
}




@media (max-width: 768px) {
  .customLinks{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    a{
      margin: 5px;
    }
    button{
      margin-right: 0px;
      font-size: 2vw;
    }
  }
.profileImage{
    width: 10rem;
    height: 10rem;
    filter: none;
	}
	
	.profile-box {
		grid-template-columns: unset;
	}
  .quote{
    display: none;
  }
  .userName {
    display: unset;
    align-items: center;
    justify-content: center;
    strong{
      font-size: 1.7rem;
    }
    button{
      font-size: 1.6vw;
      margin-bottom: 2%;
      background-color: #7c57da;
    }
  }
  .infoWrapper{
    margin-top:1%;
  }
	.user-info{
	display: unset;
	grid-template-columns: unset;
	font-size: 1.2rem;
	}
	
	.profile-box b {
		font-size: unset;
	}
	
	.user-info strong {
		font-size: 1.7rem;
	}
	
	.profile-box .profileNav span {
    margin: 5px;
    font-size: 1.2rem;
}

.user-info svg {
  width: 1.4rem;
  height: 1.4rem;
}

.factors-container {

  width: 20rem;
  height: 20rem;
}
}
.currentAnswer {
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
  color: white;
  background-color: 151c37;
  span{
    padding-right: 2%;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: left;
    background: 151c37;
    color: white;
    font-size: 1.7vw;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    width: 100%;
  }
}
.infoInputs{
  textarea{
    width: 100%;
    height: 100%;
  }
}
.infoModal{
  button{
    margin-bottom: 10px;
  }
  textarea{
    height: 30vh;
  }
}


