.DailyQuestion {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%;
    form {
      width: 100%

    }
    h1 {
      color: black;
      font-size: 2vw;
    }
 
    textarea {
      width: 100%;
      
    }
  }
  
  