.affinittyGroupsPage {
  width: 100%;
  height: 100%;
  background-color: #151c37;
  display: flex;
  flex-direction: column;
  padding: 2%;
  h1 {
    color: white;
    font-size: 3vw;
  }
}


.affinittyGroups {
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
  color: white;
  background-color: #47317d;
  h3 {
    padding: 2%;
  }

  section {
    // display: flex;
    align-items: center;
    justify-content: center;
    background: #151c37;
    color: black;
    font-size: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    
    width: 100%;
    object-fit: contain;
    // padding-top: 112px;
  }
}

@media (min-width: 992px)
{
.affinittyGroupsPage {
    width: 100%;
}
}

@media (min-width: 768px)
{
.affinittyGroupsPage {
    width: 100%;
}
}


@media (max-width: 600px)
{
.affinittyGroupsPage {
    width: 100%;
}
.affinittyGroups section{
  padding:0px;
}
}

.hide-button{
  display: none;
}

.profileBlock {
  margin-bottom: 77px;
  margin-right: 20px;
  margin-left: 20px;
}
.imageBlock {
  margin-right: 30px;
  margin-left: 30px;
  width: 186px;
  height: 186px;
  margin-bottom: 14px;
  border-radius: 100%;
  overflow: hidden;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
  img {
    // object-fit: cover;
    width: 186px; /* width of container */
    height: 186px; /* height of container */
    object-fit: cover;
    object-position:20% 50%;
    
  }
}

.textBlock {
  text-align: center ;
  .profileName{
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
  }
  .profileRole {
    font-weight: 700;
    font-size: 15px;
    line-height: 22.95px;
    font-family: Arial, Helvetica, sans-serif;
  }
}
.title {
  font-size: 2.7em;
  text-shadow: black 2px 2px 5px;
  span {
    padding: 5px;
  }
}
@media (max-width: 600px) {
  .title {
      font-size: 2.4em;
  }
  }

.linkBlock-1 {
  display: flex;
  justify-content: space-between;
  background-color: #272d46;
  border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.buttonWrapper {
  margin: 20px;
}
.button {
  height: 48px;
  background-color: #47317D;
  color: white;
  // font-weight: bold;
  // font-size: 18px;
  // line-height: 23px;
  font-family: 'Montserrat', sans-serif;
  padding-right: 30px;
  padding-left: 30px;
  border-width: 0;

}

.ag-gallery{
  background-color: #272d46;
  margin: 0px;
}

.group-overlay {
  position: relative;
  border-radius: 30px;
  // height: 360px;
}

.cursor-pointer{
  cursor: pointer;
}

.group-img-overlay {
  opacity: 0.7;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  border-radius: 30px;
  max-height: 100%;
    max-width: 100%;
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.group-title-div {
  
    /* opacity: 1; */
    position: absolute;
    top: 110px;
    left: 40px;
    text-align: left;
    /* width: 70px; */
    /* height: 70px; */
    font-size: 40px;
    /* line-height: 35px; */
    color: #FFFFFF;
    font-weight: 800;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

}


.group-description-div{

    position: absolute;
    bottom: 30px;
    left: 40px;
    text-align: left;
    width: 84%;
    padding: 6%;
    padding-left: 0px;
    padding-bottom: 2%;
}

.group-description-txt {

    font-size: 26px;
    line-height: 35px;
    color: #FFFFFF;
    font-weight: 800;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
}

.group-description-txt-name{
  color: white;
     
      font-family: Arial;
      font-size: 15px;
      line-height: 18px;
      font-style: normal;
      font-weight: bold;
      color: #FFFFFF;
}


.img-profile{
border-radius: 50%;

  width: 70px;
  height: 70px;
  border-image-width: 10px; 
  border: 4px solid #ffffff;
  max-height: 100%;
  max-width: 100%;
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
 
}
.circleBase{
  border-radius: 50%;
  width: 55px;
  height: 55px;
  background: rgba(196, 196, 196, 0.8);
  }
  
  .icon-size{
  font-size: 34px !important;
  
  }

  .btn-like{
    padding: .7rem .6em;
    color:white;
  }

  .btn-like:hover {
    color: #dc3545;
}

.btn-plus{
  padding: .7rem .9em;
    color:white;
	 border-radius: 50%;
  //background-color: grey;
  width: 70px;
  height: 70px;
  background: #47317d;
}

.plus {
  width:50px;
  height:50px;
  display:inline-block;
  
  background:
    linear-gradient(#47317d 0 0) top left,
    linear-gradient(#47317d 0 0) top right,
    linear-gradient(#47317d 0 0) bottom left,
    linear-gradient(#47317d 0 0) bottom right;
  background-size: calc(50% - 1px) calc(50% - 1px); /*thickness = 2px (2*1px) */
  background-repeat:no-repeat;
  border:10px solid #47317d; /*length = 30px (50px - 2x10px) */
  box-sizing:border-box;
  background-color: white;
}

.radius {
  border-radius:50%;
}


.img-width-res {
  height: 300px;
}

@media (min-width: 992px)
{
.img-width-res {
  height: 340px;
}
}

@media (min-width: 768px)
{
.img-width-res {
  height: 320px;
}
}


.bottom-button {
  background-color: #272d46;
  }
  
  .btn-right-corner{
  justify-content: flex-end;
      display: flex;
      padding-right: 3rem!important;
    }

    
@media (max-width: 600px)
{
.btn-right-corner {
    justify-content: unset; 
     display: unset; 
     padding-right: unset !important;
}
}
