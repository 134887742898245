.colorDiv {
    margin-top:10%;
    border-radius: 10px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h1 {
      font-weight: bold;
      margin-top: 3%;
    }
    section {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      font-size: 1.8rem;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 3%;
      width: 100%;
    }
  }

  
  @media (max-width: 768px) {
    .colorDiv {
     
      section {
       
        font-size: 1rem;
      }
    }
  }