.searchBar .search {
    margin-bottom: 0px;
}

@media (max-width: 992px) {
    .navbar.navbar-expand-lg.navbar-dark.bg-dark {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .searchBar {
        align-self: start;
        margin-left: 0px;
        text-align: start;
    }
}

@media (max-width: 600px) {
    .searchBar {
        align-self: center;
        margin-left: 20px;
    }
}