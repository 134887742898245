@import url('https://fonts.googleapis.com/css?family=Montserrat|Nunito:400,900&display=swap');
@import './globals';

* {
  font-family: 'Montserrat', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: whitesmoke;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
}

body::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: whitesmoke;
}

body::-webkit-scrollbar
{
  width: 12px;
	background-color: black;
}

body::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #555;
}

main {
  max-width: 1280px;
  margin: auto !important;
  min-height: 80vh;
  // padding: 20px 0px;
  overflow-x: hidden;
}

a {
  color: $color-purple;
}

button {
  border-radius: 290486px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  -webkit-appearance: none;
}

label{
  font-weight: 600;
}

footer {
  background-color: $color-black;
  color: $color-white;
  
  a {
    color: $color-white;
  }
}

textarea,
input {
  border-color: $color-secondary-text !important;
}

textarea:focus,
input:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border-color: rgba(106, 68, 182, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(106, 68, 182, 0.6);
  outline: 0 none;
}

.keeper-purple {
  color: $color-purple;
}

.legal {
  font-size: 0.55rem;
  text-align: left;
}

.sign-up-links {
  font-weight: 600;
  color: $color-black;
  margin-left: 0.2em;
}

.input-group-prepend {
  background-color: $color-white;
  border-color: $color-secondary-text;
  border-right: none;
}

.social-links {
  border-right: none;
}

.input-group-append {
  background-color: $color-white;
  border-color: $color-secondary-text;
  border-left: none;
}

.add-link-btn {
  padding: 0em 1.5em;
}

.fa-plus {
  margin-right: 0.3rem;
}

.fa-eye {
  color: $color-purple;
}

.fa-eye-slash {
  color: $color-purple;
}

.modal {
  padding-left: none !important;
}

.fade {
  padding-left: none !important;
}

.show {
  padding-left: none !important;
}

.modal-title {
  font-weight: 600;
}

.input-group-text {
  background-color: transparent;
  border-color: $color-secondary-text;
}

.modal-header {
  .close {
    padding: 0rem 0.3rem;
    margin: 0 .25rem;
    border-radius: 100px;
    color: $color-white;
    background-color: $color-purple;
    text-shadow: none;
    opacity: 1;
  }  
}
.dark{
  background-color: rgb(21,39,69);
}

.spinner-container {
  background-color: whitesmoke;
  height: 100vh;
}

.spinner-border {
  color: $color-purple;
  width: 5rem;
  height: 5rem;
  -webkit-animation: spinner-border 1s linear infinite;
  animation: spinner-border 1s linear infinite;
  margin: auto;
}

.sub-label {
  font-size: 0.75rem;
}

.pw-rules {
  list-style: none;
  text-align: left;
  padding: 0px;

  >li {
    text-align: left;
  }

  .fa-times {
    border-radius: 100px;
    color: $color-white;
    background-image: linear-gradient(135deg, lighten($color-red, 30%), darken($color-red, 10%)) ;
    padding: 0.25em 0.4em;
    margin: 0.5em 0.5em 0 0;
  }
  
  .fa-check {
    border-radius: 100px;
    color: $color-white;
    background-image: linear-gradient(135deg, lighten($color-green, 20%), $color-green) ;
    padding: 0.25em;
    margin: 0.5em 0.5em 0 0;
  }
}

.search-spinner {
  .spinner-border {
    color: $color-purple;
    width: 1.8rem;
    height: 1.8rem;
    -webkit-animation: spinner-border 1s linear infinite;
    animation: spinner-border 1s linear infinite;
    margin: auto;
  }
}
.manager-input{
  position: absolute;
  right: 6px;
  top: 5px;
}

.manager-input-box{
  position: relative;
  display: flex;
}

.full-name {
  color: lighten($color-lightest-purple, 20%) !important;
}

.dropdown-menu {
  a {
    color: $color-white;
  }
  
  a:hover {
    transition: 0.2s ease-in-out;
    color: $color-lightest-purple;
    text-decoration: none;
  }
}

.complete-gif-container {
  width: 200px;
  margin: 0px auto 1rem;
  cursor: pointer;
}

.complete-gif {
  object-fit: cover;
  width: 100%;
  height: 180px !important;
  border-radius: 1rem;
  border: none;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
  transition: 0.15s ease-in-out;
  margin: auto;
}

.complete-gif:hover,
.complete-gif:focus {
  transition: 0.15s ease-in-out;
  box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.5);
  transform: scale(1.05);
}

.dropdown-item {
  color: $color-white;
}

.dropdown-item:hover {
  color: lighten($color-lightest-purple, 20%) !important;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: $color-purple;
  border-radius: 100px;
}

.jumbotron {
  background-color: transparent;
  padding: 2rem 2rem 0 2rem;
}

.card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.navbar {
  background-color: black !important;
  z-index: 100;
  width: 100%;
  position: sticky !important;
  top: 0;
}

.navbar-brand {
  position: absolute;
  display: contents;
  width: 100vw;
}

.navbar-dark .navbar-nav .nav-link:focus, 
.navbar-dark .navbar-nav .nav-link:hover {
  color: lighten($color-lightest-purple, 20%);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-toggler {
  border-color: transparent;
  position: absolute;
  right: 1rem;
  top: 1.1rem;
}

.navbar-dark .navbar-nav .nav-link {
  color: $color-white;
}

.navbar-collapse {
  text-align: center;
}

.error {
  color: $color-red;
}

.card-body {
  width: 80%;
  margin: auto;
}

.gdpr {
  background-image: linear-gradient(75deg, $color-purple, $color-dark-purple);
  color: $color-white;
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: justify;
  z-index: 1;

  .fa-times {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.gdpr-text {
  margin: 30px 30px;
}

.auth-btn {
  border-radius: 20px;
  border: 2px solid;
  min-width: 100px;
}

.sign-up-btn {
  margin: 1em auto;
  background-color: $color-purple !important;
  width: 13em;
  padding: 0px 0px 0px 6px !important;
}
.sign-up-btn-loader{
  display: flex !important;
  justify-content: center !important;
  padding-top: 3px !important;

}

.fa-times {
  cursor: pointer;
}

.modal-body {
  padding: 0;

  .jumbotron {
    padding: 1rem 0rem 0rem 0rem;
    margin-bottom: 0.5rem;
  }

  .modal-legal {
    font-size: 0.5rem;
    text-align: justify;
  }

  .modal-legal-link {
    color: $color-black;
    font-weight: 600;
    margin-left: 0.3rem;
  }

  @media (max-width: 768px) {
    .jumbotron {
      padding: 2rem 0rem 0rem 0rem;
    }
  }
}

.btn-link {
  color: $color-purple;
  min-width: auto;
  box-shadow: none;
}

.btn-link:hover {
  text-decoration: none;  
}

.btn-sso {
  color: $color-white;
  border-radius: 290486px !important;
  border: none !important;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5) !important;
}

.btn-google {
  background-color: #db3236;
}

.btn-facebook {
  background-color: #3b5998;
}

.btn-google:active {
  transition: 0.15s ease-in-out;
  box-shadow: none;
  background-color: #db3236;
}

.btn-google:hover {
  background-color: lighten(#db3236, 2%);
}

.dropdown-menu {
  text-align: center;
  background-color: black;
  right: -17px !important;
  left: unset !important;
}

.btn-round-purple{
  background-color: $color-purple !important;
  color: $color-white !important;
  border-radius: 290486px !important;
  border: none !important;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5) !important;

}

.btn-back {
  background-color: white !important;
  color: $color-light-purple !important;
}

.btn-solid-purple {
  background-color: $color-purple;
  color: $color-white;
  border: none;
  padding: 0px 20px;
  height: 37px;
  min-width: 124px;
  border-radius: 30px;
  font-weight: 600;

  &.round{
    padding: 10px;
  }
}

.btn-solid-purple:disabled {
  background-color: $color-light-gray;
  cursor: not-allowed;
}

.btn-solid-purple:active {
  transition: 0.15s ease-in-out;
  box-shadow: none !important;
}

.btn-solid-purple:hover:enabled {
  background-color: $color-light-purple !important;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.5);
  transform: scale(1.01);
}

.cancel-button{
  border: none;
    padding: 0px 20px;
    height: 37px;
    min-width: 124px;
    border-radius: 30px;
    font-weight: 600;
}

.btn-outline-purple{
  background-color: transparent;
  color: $color-purple;
  border: 2px solid $color-purple;
  height: 37px;
  min-width: 124px;
  border-radius: 30px;
  font-weight: 600;
}

.btn-pagination {
  background-color: $color-purple;
  color: $color-white;
  border: none;
  height: 37px;
  width: 37px;
  border-radius: 100px;
}

.btn-disabled {
  background-color: $color-light-gray;
  color: $color-black;
  border: none;
  padding: 0px 20px;
  height: 37px;
  min-width: 124px;
  border-radius: 30px;
  font-weight: 600;

  &.round{
    padding: 10px;
  }
}

.edge-spacer {
  margin: 0rem 1.35rem;
}

.editButton{
  margin: .5em 0;
  background-color: $color-white;
  color: $color-purple;
  border: 2px solid $color-purple;
  cursor: pointer;
  justify-content: center;
  padding: calc(.375em - 1px) .75em;
  text-align: center;
  white-space: nowrap;
  border-radius: 100px;
  font-size: 0.8rem;
  font-weight: 600;
  height: 2.5em;
  min-width: 8rem;
}

.hide {
  display: none;
}

.heading{
  font-family: 'Nunito', sans-serif;
  font-size: 3rem;
  font-weight: 900;
  letter-spacing: .5rem;
  text-indent: .5rem;
  color: $color-purple;
  text-transform: uppercase;
}

.form-label {
  width: 100%;
  text-align: left;
  font-weight: 600;
}

.sign-up-form {
  width: 80%;
  margin: auto;

  .btn-primary:disabled { 
    background-color: $color-purple;
    border-color: $color-purple;
  }
}

.password-fields {
  border-right: none;
}

.password-fields {
  border-right: none;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: $color-purple;
  background-color: transparent;
}

.fa-caret-down{
  margin-left: 6px;
}

.fa-caret-up{
  margin-left: 6px;
  color: rgba(0, 0, 0, 0.5);
}

.react-pdf__Page__canvas {
  width: 100%;
  height: 100%;
  margin: auto;
}
.example-modal {
  margin: auto;
  .attribute-text{
    display: flex;
    justify-content: center;
    overflow-wrap: break-word;
    padding: 10px 20px 0px;
    min-height: 120px;
    text-align: justify;
    text-align-last: center;
  }
  
  .attributeImage{
    height: 17em;
    position: relative;
    background-color: $border-color;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .content-image{
      background-color: $color-white;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }
  }
}

.teams-logo {
  width: 300px;
  height: 60px;
}

.logo-link {
  margin: auto;
}

.company-logo {
  max-width: 200px;
  max-height: 60px;
  margin-left: 2rem;
  cursor: default;
  color: $color-white;
}

.keeper-logo {
  width: 12rem;
  height: 4rem;
}

.instruc {
  text-align: center !important;
}

@media (min-width: 768px) and (max-width: 992px) { 
  .complete-gif-container {
    transform: scale(0.8);
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 768px) { 
  .modal {
    width: 100vw;
    margin: auto;
  }

  .heading{
    font-size: 1.5rem;
  }

  .kredText {
    font-size: 0.85rem;
  }

  .kredSub {
    font-size: 1rem !important;
  }

  .instruc {
    padding-bottom: 0px !important;
  }

  .logo-link {
    margin-left: 0px !important;
    margin-right: auto !important;
  }

  .company-logo {
    max-width: 100px;
    margin-left: 1.5rem;
  }
}

@media (max-width: 375px) {
  .navbar-brand {
    padding-left: 0px;
  }

  .navbar-dark .navbar-toggler {
    right: 0.5rem;
  }

  .teams-logo {
    width: 160px;
  }

  .keeper-logo {
    width: 9rem;
    height: 4rem;
  }
}

@media (max-width: 600px) {
  .company-logo {
    max-width: 50px;
    font-size: 0.5rem;
    color: $color-white;
    margin-left: 1rem;
  }
}

@media (min-width: 375px) and (max-width: 991px) {
  .navbar-brand {
    margin: 0px auto !important;
    // padding-left: 50px;
  }

  .teams-logo {
    width: 200px;
  }
}

.resume-navCandidateView{
  padding: 20px 0;
}

.profile-pic-small {
  border-radius: 100%;
}

.justify-center{
  justify-content: center;
}

.teamMembers{
  margin-top: 50px;
  margin-bottom: 0px;
}

.noTeamMembers{
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .container {
   
    padding-right: 0px;
    padding-left: 0px;
  }
}