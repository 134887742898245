@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
.sideBar_heading {
  margin-top: 20px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
 
  b {
  font-weight: 700;
  font-size: 5em;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.subHeading_wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 15px;

}
.sideBar_subHeading {
  padding-left: 40px;
  padding-right: 40px;
  justify-items: center;
  font-weight: 400;
  line-height: 25px;
  font-size: 80%;
  font-family: Arial, Helvetica, sans-serif;
  margin: auto;
  color: black;
  background: rgba(71, 49, 125, 0.5);
  border-radius: 20px;
}
.sideBar_Menu{
  padding: 10px 15px;
  .bullets{
    display: flex;
    width: 100%;
    align-items: center;
    .bulletPoint {
      background-color: rgba(163, 152, 190, 1);
      width: 7px;
      height: 7px;
      border-radius: 100px;
    }
    a {
      margin-left: 20px;
      font-family: Arial, Helvetica, sans-serif;
      text-decoration: none;
      color: black;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
@media (min-width: 768px) {
  .sidebar {
    margin: 0;
    padding-left: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    margin-bottom: 30px;
    font-family: 'Courier New', Courier, monospace;
    height: 100%;
    width: 17%;
    float: right !important;

    position: fixed;
    top: 0;
    right: 0;
    padding: 0;
    text-align: left;

    background-color: #47317d;
    span {
      color: white;
      font-size: 20%;
    }
    aside {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      img {
        width: 3vw;
        height: 3vw;
        border-radius: 50%;
      }
      span {
        margin-left: 10%;

        font-size: 1.1vw;
      }
    }
    nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: 72%;
    }
 
    section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-color: white;
      border-radius: 20px;
      width: 80%;
      // padding: 5px;
      margin-top: 10%;
    }
    button {
      margin-top: 15%;
      width: 70%;
      font-size: 0.7vw;
      margin-bottom: 20px;
      background-color: #47317D;
      color: white;
      // font-weight: bold;
      // font-size: 18px;
      // line-height: 23px;
      font-family: 'Montserrat', sans-serif;
    }

    .tipHeadingWrapper {
      background: rgba(0, 0, 0, 0.35);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;
      max-height: max-content;
      
      .tipHeading {
        padding: 5px;
        display: flex;
        width: 100%;
      
        span {
          text-align: center;
          color: black;
          font-size: 18px;
          font-family: Arial black;
          color: white;
        }
      }
    }
    .tipTextWrapper {
      background-color: white;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      overflow: hidden;
      padding: 0 12px;
      .tipText {
        padding: 5px;
        display: flex;
        width: 100%;
        span{
          text-align: justify;
          font-family: Arial;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 10px;

          color: #000000;
        }
      }
    }
  }
  a:hover{
    color:white;
  }
  .section {
    padding: 0 !important;
    background-image: url(../../../assets/images/depositphotos_24205763-stock-photo-school-test.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: rgba(0, 0, 0, 0.35);
    
   
  }
}
.pageNav:hover{
  color:white;
}
