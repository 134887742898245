@import '../../../../styles/globals';

.replies {
    .replier-box {
        display: flex;
        flex-direction: row;
        padding: 10px 20px;
        .replier-image {
            margin: 0 10px auto 0;
            img {
                width: 42px;
                height: 42px;
                border-radius: 100%;
            }
        }
        .replier-content {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            background: #f0f0f0;
            padding: 5px 5px;
            border-radius: 10px;
            border-top-left-radius: 0;
            .add-image {
                margin: 0 0 auto 10px;
                cursor: pointer;
                user-select: none;
            }
            .content {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                textarea {
                    font-size: 0.9rem;
                }
                .post-button {
                    margin-top: 8px;
                    margin-right: auto;
                    padding: 3px 10px;
                    border-radius: 30px;
                    font-size: 0.8rem;
                    cursor: pointer;
                    user-select: none;
                    transition: 0.3s;
                    color: white;
                    background-color: $color-light-purple;
                    &:hover {
                        background-color: $color-dark-purple;
                        transition: 0.3s;
                    }
                }
            }
        }
    }
}

.images-to-reply {
    // margin-bottom: 10px;
    background-color: black;
    height: 200px;
    position: relative;
    .carousel-item {
        img {
            margin-top: 10px;
            height: 180px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.reply-list {
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    .loadmore {
        user-select: none;
        cursor: pointer;
        margin-right: auto;
        text-decoration: underline;
        margin-left: 20px;
        font-size: 0.8rem;
    }
}
.reply-data {
    display: flex;
    flex-direction: row;
    padding: 5px 20px;
    .replier-image {
        margin: 0 10px auto 0;
        img {
            width: 42px;
            height: 42px;
            border-radius: 100%;
        }
    }
    .reply-content {
        width: 100%;
        background-color: #f0f0f0;
        padding: 5px 10px;
        border-radius: 10px;
        border-top-left-radius: 0;
        .reply-header {
            display: flex;
            flex-direction: row;
            .repiler-name {
                font-weight: bold;
                font-size: 0.8rem;
            }
            .repiler-role {
                font-size: 0.7rem;
                opacity: 0.7;
            }
            .creation-time {
                margin-left: auto;
                font-size: 0.7rem;
            }
            .reply-action {
                top: -8px;
                position: relative;
                .dropdown {
                    .btn-primary {
                        background: none !important;
                    }
                }
                .dropdown-toggle {
                    color: $color-secondary-text;
                    background-color: none;
                    border: none;
                    box-shadow: none;
                    &::after {
                        display: none;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                }
                .dropdown-menu {
                    background-color: white;
                    transition: 0.3s;
                    a {
                        font-size: 0.9rem;
                        color: $color-secondary-text !important;
                        font-weight: 600;
                        text-align: left;
                    }
                }
            }
        }
        .comment {
            font-size: 0.85rem;
            padding: 5px;
        }
        .edit-images {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            .add-image {
                width: 26px;
                height: 26px;
                font-size: 0.8rem;
                border-radius: 100%;
                background-color: $color-light-purple;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: white;
                cursor: pointer;
                user-select: none;
                transition: 0.3s;
                margin-right: 10px;
                &:hover {
                    background-color: $color-dark-purple;
                    transition: 0.3s;
                }
                span {
                    margin: auto;
                }
            }
        }
        .edit-action {
            display: flex;
            flex-direction: row;
            margin-top: 5px;
            margin-bottom: 10px;
            .action-button {
                display: flex;
                flex-direction: row;
                padding: 3px 10px;
                cursor: pointer;
                user-select: none;
                border-radius: 20px;
                color: white;
                background-color: $color-light-purple;
                font-size: 0.8rem;
                font-weight: bold;
                margin-right: 10px;
                &:hover {
                    background-color: $color-dark-purple;
                    transition: 0.3s;
                }
            }
        }
        .reply-status {
            display: flex;
            flex-direction: row;
            border-top: 1px solid #e0e0e0;
            padding: 0 10px;
            .like-icon {
                cursor: pointer;
                user-select: none;
                font-size: 0.8rem;
            }
            .like-count {
                font-size: 0.8rem;
                color: gray;
                font-weight: 600;
                margin: auto 5px;
            }
        }
    }
}