@import '../../styles/globals';

main {
  max-width: none !important;
}
// .fivefactors_container{
//   padding: 0 100px ;
// }

.MyProfile{
  text-align: center;
  width: 100%;
  padding-bottom: 150px;

  .officeInfo{
    color: $color-white;
  }

  .review {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: .5rem;
    text-indent: .5rem;
    margin-bottom: 20px;
    color: $color-purple;
  }

  .view-profile {
    height: 80px;
  }

  .profile-title {
    width: auto;
    margin: 0px auto;
  }

  .candidate-info {
    background-image: linear-gradient(75deg, $color-purple, $color-dark-purple);
    height: auto;
    padding-bottom: 20px;
  }

  .candidate-info-top{
    top:160px;
  }

  .column-info{
    max-width: 1280px;
    margin: 0px auto;
  }

  .column-name {
    width: 100%;
  }

  .header-text {
    color: $color-white;
  }

  .profile-pic {
    object-fit: cover;
    width:100%;
    height:100%;
    border-radius: 50%;
    border: 2px solid $color-purple;
  }

  .profile-pic-container {
    background-color: none;
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: relative;
    top: -60px;
    margin: auto;
  }

  .profile-name {
    font-size: xx-large;
    line-height: 85%;
    width: auto;
    max-width: 90vw;
    display: block;
    color: $color-white;
    margin: -50px auto 0px auto;
    word-wrap: break-word;
    text-align: center;
    text-transform: uppercase;
  }

  .icons {
    width: 100%;
    margin-top: 0.75em;
  }

  .info-container {
    color: $color-white;
    text-align: center;
    margin-top: 0.75em;
  }

  .render-attributes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
    margin-top: 30px;
  }

  .icon-link {
    color: $color-white !important;
    font-size: 1.5rem;
    margin-right: 1em;
  }

  .icon-link:last-of-type {
    margin-right: 0px !important;
  }

  .resume-render {
    width:90% !important;
    max-width: 1000px;
    height:90% !important;
    overflow: hidden;
    margin: auto;
    outline: 1px solid $color-black;
  }

  .resume-container {
    margin: auto;
    margin-bottom: 30px;
    padding-bottom: 60px;
    background-color: $color-white;
    border-radius: 1.25rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .resume-container-download {
    padding-bottom: 0px;
  }

  .resume-header {
    font-weight: 600;
    font-size: 2rem;
    height: 100px;
    margin-top: 0.5em;
    padding-top: 1em;
  }

  .resume-disabled{
    display: none;
  }

  .footer-container {
    // width: 88%;
    display: flex;
    flex-direction: column;
    margin: 1.25em auto 0px;
    text-align: left;
  }

  .footer-container-text {
    margin: 1vw 0 3vw 0;
    // line-height: 4vw;
  }

  .editButtonView, .is-grouped{
    margin: 0px auto 20px auto;
  }

  .end-btn-group {
    display: flex;
    justify-content: space-evenly;
    width: 290px;
    margin: auto;
  }

  input[id="check_2"] + label {
    position: relative;
  }

  input[id="check_2"] + label::before {
    width: 22px;
    height: 22px;

    background: #f2f2f2;
    border: 1.5px solid $color-black;
    content: "";

    display: inline-block;
    position: relative;
    margin-right: 10px;
    top: 6px;
  }

  /* style checkmark symbol */
  input[id="check_2"]:checked + label::after {
    width: 14px;
    height: 7px;

    content: "";
    border-left: 2px solid #6a44b6;
    border-bottom: 2px solid #6a44b6;

    top: .7rem;
    left: .25rem;
    position: absolute;
    transform: rotate(-45deg);
  }

  /* hide default checkbox */
  input[id="check_2"] {
    width: 1px;
    height: 1px;

    overflow: hidden;
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
  }

  .download {
    margin: 20px 0px;
    height: 50px;
    width: 170px;
    padding-left: 1em;
    padding-right: 1em;
    box-sizing: border-box;
    cursor: pointer;
    justify-content: center;
    padding: calc(.375em - 1px) .75em;
    text-align: center;
    white-space: nowrap;
    line-height: 1.5;
    display: inline-flex;
    font-size: 1rem;
    -webkit-appearance: none;
    align-items: center;
    position: relative;
    vertical-align: top;
    text-decoration: none;
  }

  @media (min-width: 1280px) {
    .fivefactors_container{
      padding: 0 10% ;
    }
    .profile-pic-container {
      width: 200px;
      height: 200px;
    }

    // .render-attributes {
    //   margin-top: 100px;
    // }
  }

  @media (min-width: 850px) and (max-width: 1300px) {
    .fivefactors_container{
      padding: 0 10% ;
    }
  }


  @media (min-width: 750px) and (max-width: 900px) {
    .fivefactors_container{
      padding: 0 5% ;
    }
  }


  @media (min-width: 500px) and (max-width: 768px) {
    .fivefactors_container{
      padding: 0 8% ;
    }
    .profile-pic-container {
      width: 120px;
      height: 120px;
    }

    .profile-name {
      font-size: 1.3rem;
      max-width: calc(100vw - 20px);
    }

    .column-info {
      padding: 0px 20px !important;
    }

    .checkbox-container {
      font-size: 0.9rem;;
    }
  }


  @media (min-width: 321px) and (max-width: 530px) {
    .fivefactors_container{
      padding: 0 10px ;
    }
    .profile-title {
      font-size: 1.5rem;
    }

    .checkbox-container {
      font-size: 0.6rem !important;
    }

    input[id="check_2"] + label::before {
      width: 20px;
      height: 20px;
    }

    input[id="check_2"]:checked + label::after {
      width: 12px;
      height: 6px;
    }
  }

  @media (min-width: 320px) and (max-width: 499px) {
    .fivefactors_container{
      padding: 0 5px ;
    }
    .profile-name {
      font-size: 1rem;
      max-width: calc(100vw - 20px);
    }

    .column-info {
      padding: 0px 20px !important;
    }

    .profile-pic-container {
      width: 120px;
      height: 120px;
    }
  }

  @media (max-width: 320px) {
    .fivefactors_container{
      padding: 0 5px ;
    }
    .profile-title {
      font-size: 1.25rem;
    }

    .checkbox-container {
      font-size: 0.5rem !important;
    }

    input[id="check_2"] + label::before {
      width: 16px;
      height: 16px;
    }

    input[id="check_2"]:checked + label::after {
      width: 8px;
      height: 5px;
    }
  }


}