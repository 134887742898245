.teamVIPage {
  width: 100%;
  height: 100%;
  background-color: #151c37;
  display: flex;
  flex-direction: column;
  padding: 2%;
  h1 {
    color: white;
    font-size: 3vw;
  }
}
@media (min-width: 992px)
{
.teamVIPage {
    width: 100%;
}
}

@media (min-width: 768px)
{
.teamVIPage {
    width: 100%;
}
}


@media (max-width: 600px)
{
.teamVIPage {
    width: 100%;
}
}



.teamVI {
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
  color: white;
  background-color: #47317d;
  h3 {
    padding: 2%;
  }

  section {
    // display: flex;
    align-items: center;
    justify-content: center;
    background: #151c37;
    color: black;
    font-size: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
   
    width: 100%;
    object-fit: contain;
    // padding-top: 112px;
  }
}


@media (max-width: 600px)
{
.teamVI section {
    padding: 0px;
}
}

.hide-button{
  display: none;
}

.profileBlock {
  margin-bottom: 77px;
  margin-right: 20px;
  margin-left: 20px;
}
.imageBlock {
  margin-right: 30px;
  margin-left: 30px;
  width: 186px;
  height: 186px;
  margin-bottom: 14px;
  border-radius: 100%;
  overflow: hidden;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
  img {
    // object-fit: cover;
    width: 186px; /* width of container */
    height: 186px; /* height of container */
    object-fit: cover;
    object-position:20% 50%;
    
  }
}

.textBlock {
  text-align: center ;
  .profileName{
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
  }
  .profileRole {
    font-weight: 700;
    font-size: 15px;
    line-height: 22.95px;
    font-family: Arial, Helvetica, sans-serif;
  }
}
.title {
  font-size: 2.7em;
  text-shadow: black 2px 2px 5px;
  span {
    padding: 5px;
  }
}
@media (max-width: 600px) {
  .title {
      font-size: 2.4em;
  }
  }

.linkBlock-1 {
  display: flex;
  justify-content: space-between;
  background-color: #272d46;
  border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.buttonWrapper {
  margin: 20px;
}
.button {
  height: 48px;
  background-color: #47317D;
  color: white;
  // font-weight: bold;
  // font-size: 18px;
  // line-height: 23px;
  font-family: 'Montserrat', sans-serif;
  padding-right: 30px;
  padding-left: 30px;
  border-width: 0;

}

.vi-gallery{
  background-color: #272d46;
  margin: 0px;
}

.i-overlay {
  position: relative;
  border-radius: 30px;
  // height: 360px;
}

.img-overlay {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  // border-radius: 30px;
  max-height: 75%;
    max-width: 100%;
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: inherit;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.top-left-corner {
  transition: .5s ease;
  opacity: 1;
  position: absolute;

  top: 25px;
    left: 40px;
  // transform: translate(-50%, -50%);
  // -ms-transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 55px;
    height: 55px;

}

.top-right-corner{
transition: .5s ease;
  opacity: 1;
  position: absolute;
  top: 25px;
    right: 40px;
  
  text-align: left;
}

.bottom-left-corner{

    /* position: absolute; */
    /* bottom: 15px; */
    /* left: 40px; */
    text-align: left;
    /* width: 84%; */
    padding: 3%;
    /* padding-left: 0px; */
    /* padding-bottom: 2%; */
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    background: rgba(71, 49, 125, 0.8);
    height: 84px;
}

.i-txt {

  font-size: 15px;
  line-height: 30px;
      color: #FFFFFF;
      font-weight: 800;
    font-style: normal;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif !important;
}

.i-txt-name{
  color: white;
     
      font-family: Arial;
      font-size: 15px;
      line-height: 18px;
      font-style: normal;
      font-weight: bold;
      color: #FFFFFF;
}


.img-profile{
border-radius: 50%;

  width: 70px;
  height: 70px;
  border-image-width: 10px; 
  border: 4px solid #ffffff;
  max-height: 100%;
  max-width: 100%;
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
 
}
.circleBase{
  border-radius: 50%;
  width: 55px;
  height: 55px;
  background: rgba(196, 196, 196, 0.8);
  margin: auto;
  }

  .circle-badge-div{
    border-radius: 50%;
    width: 55px;
    height: 55px;
    border: 4px solid #FFFFFF;
    padding-left: .6rem!important;
    margin: auto;
    }

    @media (max-width: 768px)
{
.circle-badge-div {
   padding-left: 0rem !important;
}
}
  
  .icon-size{
  font-size: 34px !important;
  
  }

  .icon-badge-size{
    font-size: 32px !important;
    
    }

  .btn-like{
    padding: .7rem .6em;
    color:white;
  }

  .btn-like:hover {
    color: #dc3545;
}

.btn-plus{
  padding: .7rem .9em;
    color:white;
	 border-radius: 50%;
  //background-color: grey;
  width: 70px;
  height: 70px;
  background: #47317d;
}

.plus {
  width:50px;
  height:50px;
  display:inline-block;
  
  background:
    linear-gradient(#47317d 0 0) top left,
    linear-gradient(#47317d 0 0) top right,
    linear-gradient(#47317d 0 0) bottom left,
    linear-gradient(#47317d 0 0) bottom right;
  background-size: calc(50% - 1px) calc(50% - 1px); /*thickness = 2px (2*1px) */
  background-repeat:no-repeat;
  border:10px solid #47317d; /*length = 30px (50px - 2x10px) */
  box-sizing:border-box;
  background-color: white;
}

.radius {
  border-radius:50%;
}


.img-width-res {
  height: 300px;
}

@media (min-width: 992px)
{
.img-width-res {
  height: 340px;
}
}

@media (min-width: 768px)
{
.img-width-res {
  height: 320px;
}
}

.btn-like-active {
    color: #dc3545;
}
	

.btn-like:focus{
	box-shadow: none;
}

.likes-txt{
		
  border-radius: 60px;

background: rgba(196, 196, 196, 0.8);
text-align: center;
vertical-align: middle;
color: white;
}

.bottom-button {
  background-color: #272d46;
  }

  .cursor-pointer{

    cursor: pointer;
  color:white;
}

.cursor-pointer:hover{
color:white;
text-decoration: none;
}