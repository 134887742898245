@import '../../styles/globals';

.FOF-container {
  text-align: center;
  .gifContainer{
    // margin-left: calc(50% - 50vw);
    width: 100%;
  }
  .FOFtitle{
    font-weight: 600;
    font-size: 70px;
    color: white;
  }
  .FOFsubTitle{
    color: white;
    margin-bottom: 20px;
  }
  img {
    height: auto;
    max-width: 100%;
  }
}