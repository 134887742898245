@import '../../styles/globals';

.cards{
  * {
    
  }

  .col-md-6:nth-of-type(5) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .flip{
    height: 20px;
    margin: 0px 10px 3px 0px;
    cursor: pointer;
  }

  .fa-plus-circle {
    margin: 0em 0.25em 0em -.25em;
    font-size: 1rem;
  }

  .flip-card {
    background-color: transparent;
    perspective: 1000px;
  }
   
  .flip-card-inner {
    position: relative;
    width: 100%;
    text-align: center;
    transition: transform 0.2s;
    transform-style: preserve-3d;
    border-radius: 20px;
  }
   
  .flip0, .flip1, .flip2, .flip3, .flip4, .flip5{
    transform: rotateY(180deg);
  }

  .flipShow{
    visibility: visible;
    opacity: 1;
    transition: visibility .1s, opacity .1s ease-in;
  }
  
  .flipHide{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0px;
    visibility: hidden;
    opacity: 0;
    transition: visibility .1s, opacity .1s ease-out;
  }
   
  .flip-card-front {
    color: black;
    border-radius: 20px;
  }
   
  .flip-card-back {
    transform: rotateY(180deg);
    border-radius: 20px;

    .flip{
      margin: 2px 0px 10px 0px;
    }
  }

  .sample-text {
    color: $color-purple;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .card-flip {
    cursor: pointer;
  }

  .col-md-6{
     padding: 0px;
  }

  .cards-container {
    width: 96%;
    margin: auto;
  }

  .card-body{
    padding: 20px 0px 2px 0px;
    width: 100%;

    .btn-link {
      padding: 0 0 0 0;
    }
  }

  .card{
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 4%;
  }

  .my-content-textarea {
    width: 100%;
    min-height: 120px;
    height: 100%;
    border-radius: 10px;
    border: none;
    overflow: auto;
    resize: none;
    padding-bottom: 0px;
  }

  .my-content-textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: whitesmoke;
  }

  .my-content-textarea::-webkit-scrollbar {
    width: 12px;
    background-color: $color-white;
  }

  .my-content-textarea::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #555;
  }

  @media (max-width: 768px){
    .cards-container > .card:nth-of-type(odd) {
      margin: 10px;
    }
  
    .cards-container > .card:nth-of-type(even) {
      margin: 10px;
    }
  }
}
.singleCard{
  .attributeHeader{
    min-height: 3rem;

    .attributeHeading{
      font-family: 'Nunito', sans-serif;
      font-size: 1.125rem;
      font-weight: 600;
      text-transform: uppercase;
      color: $color-purple;
    }
    .attributeSub{
      padding: 0px 20px 10px 20px;
      font-weight: 600;
      font-size: 0.975rem;
      color: lighten($color-black, 25%);
      height: 3rem;
    }
  }
  .homeAttributeHeader {
    height: 3rem;
  }
  .attributeImage{
    height: 17em;
    position: relative;
    background-color: $border-color;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .content-image{
      background-color: $color-black;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    .add-content{
      margin: auto;
    }
  }

  .attributeText{
    display: flex;
    justify-content: center;
    overflow-wrap: break-word;
    padding: 10px 20px 0px;
    min-height: 160px;
    min-width: 18rem;
    text-align: justify;
    text-align-last: center;
    color: lighten($color-black, 25%);
    font-size: 0.975rem;
    font-weight: 600;

    .description-text{
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      margin: auto;
    }

    .sm-text{
      font-size: 0.875rem;
    }
  }

  hr{
    border: 1.8px solid #C8C8C8;
    margin: 20px 50px;
  }

  iframe{
    width: 100%;
    height: 100%;
    border:none !important;
  }
  .iframe-container {
    top: 50% !important;
    bottom: initial !important;
    left: 50% !important;
    right: initial !important;
    width: 100% !important;
    height: 100% !important;
  }
  .iframe-container iframe, .embed-container object, .embed-container embed{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    // .attributeHeader{
    //   height: 4.75rem;
    // }

    .homeAttributeHeader {
      height: 1.5rem;
    }
  }
}

.modalContent {
  height: 17em;
  max-height: 100% !important;
  max-width: 99.9% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 1200px) {
  .modalContent {
    height: 100%;
  }
}