.searchBar {
    background-color: white;
    display: flex;
    margin-left: 30px;
    border-radius: 10px;
}

.searchInputs {
    display: flex;
    border-radius: 10px;
}

.inputField {
    box-sizing: border-box;
    background-color: transparent;
    border: 0;
    font-size: 18px;
    padding: 5px;
    height: 35px;
    width: 200px;
    color: black;
}

.searchBar input:focus {
    box-shadow: none;
}

.searchIcon {
    height: 35px;
    background-color: transparent;
    border-radius: 10px;
    color: rgb(27, 27, 27);
    border: 0;
    display: grid;
    place-items: center;
    margin-left: 5px;
}

.closeIcon {
    height: 35px;
    background-color: white;
    border-radius: 10px;
    color: black;
    border: 0;
    display: grid;
    place-items: center;
    margin-right: 5px;
}

.clearBtn {
    cursor: pointer;
}

.searchBtn {
    cursor: pointer;
}

.searchIcon svg {
    font-size: 30px;
}