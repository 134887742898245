.fiveFactors {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background:#47317D;;
  background-repeat: round;
  // background-size: 100%;
  text-align: center;
  border-radius: 10px;
  color: white;
      canvas {
      color: white;
      outline: none;
    }

}


.factorsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.factors-container {
  margin-top: 2.5%;
  position: relative;
  width: 60vw;
  height: 60vw;
  text-align: center;
  background-color:white;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  right: 5%;
  transform: rotate(-1deg)



}

@media (max-width: 768px)
{
.factors-container {
    width: 20rem;
    height: 20rem;
}
.factors-container .labels{
  right: -90%;

 
}

}
.sliceWrapper {
  position: relative;
  top:48%;
  left:68%;
  padding: 0;
  width: 20%;
  height: 20%;
  transform: rotate(19deg);
  list-style: none;
 
  
  
}
.green-1 {
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(126deg) ;
  
  
  overflow: hidden;
  width: 18.9vw;
  height: 13vw;
  background: #27ae60;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  right:85.989%;

}
.green-1:hover{
 opacity: 1;
}

.green-2 {
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(126deg) ;
  
  
  overflow: hidden;
  width: 23.3vw;
  height: 16vw;
  background: #27ae60;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  right:67%;
  
}
.green-2:hover{
 opacity: 1;
}
.green-3{
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(126deg) ;
  
  overflow: hidden;
  width: 27.6vw;
  height: 19vw;
  background: #27ae60;

  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);

}
.green-3:hover{
 opacity: 1;
}
.blue-1{
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(198deg) ;
  
  overflow: hidden;
  width: 18.9vw;
  height: 13vw;
  background: #3498db;
  right:85.989%;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);

}
.blue-1:hover{
 opacity: 1;
}
.blue-2{
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(198deg) ;
  
  overflow: hidden;
  width: 23.3vw;
  height: 16vw;
  background: #3498db;
  right:68%;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);

}
.blue-2:hover{
 opacity: 1;
}
.blue-3 {
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(198deg) ;
  
  overflow: hidden;
  width: 27.6vw;
  height: 19vw;
  background: #3498db;

  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.blue-3:hover{
 opacity: 1;
}
.purple-1{
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(-90deg) ;
  
  overflow: hidden;
  background: #8e44ad;
  width: 18.9vw;
  height: 13vw;
  right: 86.3%;

clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.purple-1:hover{
 opacity: 1;
}
.purple-2 {
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(-90deg) ;
  
  overflow: hidden;
  background: #8e44ad;
  width: 23.3vw;
  height: 16vw;
  right: 68%;
clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.purple-2:hover{
 opacity: 1;
}
.purple-3 {
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(-90deg) ;
  
 overflow: hidden;
 background: #8e44ad;
width: 27.6vw;
height: 19vw;

clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.purple-3:hover{
 opacity: 1;
}
.red-1{
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(-18deg) ;
  
  width: 18.9vw;
  height: 13vw;
  background: #e74c3c;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  right: 86%;

}
.red-1:hover{
 opacity: 1;
}
.red-2 {
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(-18deg) ;
  
  width: 23.3vw;
  height: 16vw;
  background: #e74c3c;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  right: 68%;
}
.red-2:hover{
 opacity: 1;
}
.red-3{
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(-18deg) ;
  
  width: 27.6vw;
  height: 19vw;
  background: #e74c3c;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  
}
.red-3:hover{
 opacity: 1;
}
.yellow-1{
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(54deg) ;
  
  width: 18.9vw;
  height: 13vw;
  background: #f1c40f;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  right:85.989%;
}
.yellow-1:hover{
  opacity: 1;
}
.yellow-2{
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(54deg) ;
  
  width: 23.3vw;
  height: 16vw;
  right: 67%;
  background: #f1c40f;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.yellow-2:hover{
 opacity: 1;
}
.yellow-3 {
  position: absolute;
  top: 50%;
  right:50%;
  width: 100%;
  height: 100%;
  transform-origin: 50% 0%;
  opacity: .9;
  overflow: hidden;
  transform: rotate(54deg) ;
  
  width: 27.6vw;
  height: 19vw;
  background: #f1c40f;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.yellow-3:hover{
 opacity: 1;
}

.mentor {
  z-index: 23;
  position: absolute;
  transform: rotate(-37deg);
  color: #f1c40f;
  top:9%;
  left:9%;
}
.empath {
  z-index: 23;
  transform: rotate(109deg);
  position: absolute;
  color: #27ae60;
  top: 61%;
  left:83%;
}
.leader {
  z-index: 23;
  transform: rotate(39deg);
  position: absolute;
  color: #3498db;
  top: 9%;
  left: 68%;
}
.entrepreneurial {
  z-index: 23;
  transform: rotate(-106deg);
  position: absolute;
  color: #8e44ad;
  top: 61%;
  left: -16%;
}
.risk-taker {
  z-index: 23;
 
  position: absolute;
  color: #e74c3c;
  top: 95%;
  left: 36.5%;
  transform: rotate(1deg);
}

.labels{
  
  font-size: 2vw;
  font-weight: bold;
}

.footer-items{
  display: flex;
  flex-direction: column;
  align-items: center;
  Button{
    margin-bottom:10px;
  }
  Button:hover{
    background-color: #6A44B6;
    color: white;
  }

}