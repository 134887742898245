body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 600px)
{
.img-logo-width {
    width: 60%;
}
}

@media (max-width: 600px) {
  .title {
    font-size: 2em;
    line-height: 1em;
    margin: 5px 0px;
  }
  }

  .spinner-border-2 {
   
    width: 2rem !important;
    height: 2rem !important;
    margin: unset !important;
  }

  .end-btn-group-loading
  {
    width: unset;
    display: flex;
    justify-content: center;
    /* width: 290px; */
    margin: auto;
  }

  .load-text{
    margin:auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  
 