@import '../../../styles/globals';

footer {
  background-color: $color-black !important;
  color: $color-white;
  padding: 2rem 2rem;
  z-index: 1;
  position: relative;

  .teams-footer-logo {
    width: 12rem;
    margin-left: -0.25rem;
  }
}

.footer-content {
  max-width: 1400px;
  margin: auto;
}

.footer-left {
  display: flex !important;
  flex-direction: column;
  font-size: 0.8rem;
  text-align: left;
  font-weight: 100 !important;

  .show-text {
    font-weight: 600;
    color: lighten($color-purple, 35%);
  }
}

.k-logo {
  width: 140px;
  margin: 0.5em 1em auto 0 !important;
}

.footer-left-link {
  color: $color-white !important;
  text-decoration: underline;
  font-size: 0.7rem;
  margin: auto;
}

.footer-router-link {
  color: $color-white !important;
  font-weight: 400;
  text-decoration: none;
  font-size: 0.9rem;
}

.footer-links {
  text-align: right;
  margin: auto;
}

.footer-text-links {
  padding: 0px 10px !important;
}

.footer-text-links:last-of-type {
  padding: 0px 10px 20px !important;
}

.footer-link {
  color: $color-white !important;
  font-size: 1.5rem;
  margin: 0 0.35em !important;
}

@media (min-width: 320px) and (max-width: 768px) {
  * {
    text-align: center;
  }

  .footer-links {
    text-align: center;
  }
}