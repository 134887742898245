@import '../../styles/globals';

.search{
  .input {
    width: 100%;
    color: $color-black !important;
    border: 1px solid gray;
    border-radius: .25rem;
    font-size: 1.25rem;
    height: 2.25em;
    justify-content: flex-start;
    line-height: 1.5;
    margin: 10px 0px;
  }
  .input[value] {
    padding-left: 50px;
    padding-right: 20px;
  }

  .icon{
    position: absolute;
    top: 18px;
    left: 30px;
    font-size: 20px;
    opacity: .4;
  }

  .searchbtn{
    background-color: $color-white;
    color: $color-purple;
    border: 2px solid $color-purple;
    margin: 10px 5px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
    font-size: 20px;

    cursor: pointer;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    border-radius: 100px;
    font-size: 1.25rem;
    height: 2.25em;
    min-width: 8rem;
  }

  .searchbtn:hover {
    transition: 0.1s ease-in-out;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.5);
    transform: scale(1.01);
    background-color: $color-light-purple !important;
  }
  
  .searchbtn:active {
    transition: 0.1s ease-in-out;
    box-shadow: none;
    transform: scale(0.99);
  }

  .searchbtnActive{
    background-color: $color-purple;
    color: $color-white;
  }

  .searchbtn:hover, .loadMoreBtn:hover{
    background-color: $color-purple;
    color: $color-white;
  }

  .searchButtonsDiv{
    text-align: center;
  }

  @media (max-width: 768px) {
    .searchbtn{
      background-color: transparent !important;
      color: #E0E0E0 !important;
      border: none !important;
      border-radius: 0px !important;
      border-bottom: 3px solid #E0E0E0 !important;
      box-shadow: none !important;
      font-size: 16px !important;
      min-width: 50px !important;
      margin-top:0px;
      margin-bottom:0px;
    }
    .searchbtn:hover{
      background-color: transparent !important;
    }
    .searchbtnActive{
      color: $color-black !important;
      border-bottom: 3px solid $color-purple !important;
      box-shadow: none !important;
    }
  }
}

.ccc-container {
  max-width: 1200px;
  width: 100%;
}

.attributeHeading {
  margin-top: 1rem !important;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: $color-purple;
}

.render{
  .contentDiv {
    height: 150px;
    width: 255px;
    position: relative;
    /*background-color: #7846c2*/
    background-color: black;
    margin: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    // border: 9px solid #E3E3E3;
    // border-radius: 10px;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: $color-purple;
  }
  
  .overlay:hover {
    opacity: .5;
  }

  .searchDiv {
    padding: 10px
  }

  .content {
    // height: 100%;
    max-height: 100%;
    max-width: 99%;
    /*display: block;
    margin-left: auto;
    margin-right: auto;*/
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .render-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin: auto;
    /*flex-direction: row;
    flex-grow: 1;
    margin: auto;
    width: 100%;*/
  }
}

.modal-content {
  border-radius: 1rem;
}

.loadMoreButtonsDiv {
  margin: 10px auto;
  text-align: center;

  .loadMoreBtn {
    background-color: $color-white;
    color: $color-purple;
    border: 2px solid $color-purple;
    // margin: 10px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5) !important;
    font-size: 20px;

    cursor: pointer;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    border-radius: 20px;
    font-size: 1rem;
    height: 2.25em;
    min-width: 8rem;
    font-weight: 600;
  }
}

.sponser{
  text-align: center;
}

.modal-header, .modal-footer{
  border: none;
}

.modal-header{
 padding: 0px;
}

.modal-footer{
  text-align: center;
  padding-top: 0px;
  .submitDiv{
    width: 100%;
  }
  .saveBtn {
    height: 30px;
    width: 100px;
    border-radius: 20px;
    font-size: medium;
    margin: 5px 10px;
  }
 }

.close{
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
}

.modal-body{
  text-align: center;
  padding: 2rem 2rem 0rem 2rem !important;
  .contentDiv {
    height: 300px;
    position: relative;
    background-color: black;
    margin: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .editButton{
    margin-top:10px;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: $color-purple;
  }
  .content {
    // height: 100%;
    max-height: 100%;
    max-width: 99%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  #attributeText{
    margin: 10px;
    .textAreaCounter {
      position: relative;
      margin-bottom: 0px;
      margin-top: 10px;
      font-size: 11px;
      text-align: left;
    }
    .attributeError{
      border-color: red;
    }
    
    #attributeTextArea{
      width: 100%;
      display: block;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      height: 100px;
      resize: none;
      padding: 10px;
      font-size: 13px;
    }
  }
  iframe{
    width: 100%;
    border:none !important;
  }
  .iframe-container {
    top: 50% !important;
    bottom: initial !important;
    left: 50% !important;
    right: initial !important;
    width: 100% !important;
    height: 100% !important;
  }
  .iframe-container iframe, .embed-container object, .embed-container embed{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.tip{
  text-align: center;
  font-size:90%;
 
  
}