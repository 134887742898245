@import '../../../styles/globals';

.post-image-selector {
    padding: 20px 0 30px 0;
    .save-button {
        color: white;
        background-color: $color-light-purple;
        font-weight: bold;
        text-align: center;
        padding: 5px 20px;
        border-radius: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        user-select: none;
        transition: 0.3s;
        &:hover {
            background-color: $color-dark-purple;
            transition: 0.3s;
        }
    }
    .imgdelete-button {
        position: absolute;
        left: 10px;
        bottom: 0;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-color: $color-light-purple;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        cursor: pointer;
        user-select: none;
        transition: 0.3s;
        z-index: 10;
        &:hover {
            background-color: $color-dark-purple;
            transition: 0.3s;
        }
        span {
            margin: auto;
        }

    }
    .search-input {
        padding-bottom: 10px;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        .search-text {
            margin: auto 0;
            flex-grow: 1;
        }
        .type-button {
            background-color: white;
            color: $color-light-purple;
            &.selected {
                color: white;
                background-color: $color-light-purple;
            }
            font-weight: bold;
            border: 3px solid $color-light-purple;
            margin-left: 20px;
            text-align: center;
            padding: 5px 20px;
            border-radius: 20px;
            cursor: pointer;
            user-select: none;
            transition: 0.3s;
            &:hover {
                background-color: $color-dark-purple;
                transition: 0.3s;
            }
        }
    }
}

.post-block {
    background-color: white;
    border-radius: 10px;
    // height: 500px;
    margin-bottom: 10px;
    .poster-box {
        padding: 10px 20px;
        border-bottom: $gray-border;
        display: flex;
        flex-direction: row;
        .poster_image {
            margin: 5px 0;
            width: 56px;
            height: 56px;
            border-radius: 100%;
        }
        .poster-detail {
            margin-left: 10px;
            .poster-name {
                font-size: 14px;
                font-weight: bold;
                color: black;
                cursor: pointer;
                user-select: none;
                text-decoration: underline;
            }
            .poster-role {
                font-size: 12px;
            }
            .poster-date {
                font-size: 12px;
            }
        }
        .poster-action {
            font-size: 1.4rem;
            margin: auto 0 auto auto;
            width: 30px;
            height: 30px;
            text-align: center;
            .dropdown-toggle {
                border-radius: 100%;
                color: $color-secondary-text;
                background-color: white;
                border: none;
                &::after {
                    display: none;
                }
                &:focus {
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
                }
            }
            .dropdown-menu {
                background-color: white;
                transition: 0.3s;
                a {
                    font-size: 0.9rem;
                    color: $color-secondary-text !important;
                    font-weight: 600;
                    text-align: left;
                }
            }
        }
    }
    .post-content {
        padding: 10px 20px;
        white-space: pre-wrap;
        font-size: 0.9rem;
        .edit-images {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            .add-image {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                background-color: $color-light-purple;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: white;
                cursor: pointer;
                user-select: none;
                transition: 0.3s;
                margin-right: 10px;
                &:hover {
                    background-color: $color-dark-purple;
                    transition: 0.3s;
                }
                span {
                    margin: auto;
                }
            }
        }
        .edit-action {
            display: flex;
            flex-direction: row;
            margin-top: 5px;
            .action-button {
                display: flex;
                flex-direction: row;
                padding: 3px 10px;
                cursor: pointer;
                user-select: none;
                border-radius: 20px;
                color: white;
                background-color: $color-light-purple;
                font-size: 0.8rem;
                font-weight: bold;
                margin-right: 10px;
                &:hover {
                    background-color: $color-dark-purple;
                    transition: 0.3s;
                }
            }
        }
    }
    .post-status {
        padding: 5px 20px;
        font-size: 0.8rem;
        border-top: $gray-border;
        display: flex;
        flex-direction: row;
        color: $color-secondary-text;
        .liked-count {
            margin-left: 10px;
            font-weight: bold;
        }
        .replies-count {
            margin-left: auto;
            font-weight: bold;
            cursor: pointer;
            user-select: none;
            text-decoration: underline;
        }
    }
    .post-footer {
        padding: 10px 20px;
        border-top: $gray-border;
        display: flex;
        flex-direction: row;
        .action-button {
            display: flex;
            flex-direction: row;
            padding: 10px 15px;
            cursor: pointer;
            user-select: none;
            border-radius: 20px;
            border: 2px solid $color-light-purple;
            margin-right: 20px;
            .action-icon {
                margin: auto 0;
            }
            .action-name {
                font-size: 0.9rem;
                padding-left: 10px;
                font-weight: bold;
                color: black;
            }
            &.liked {
                background: $color-light-purple;
                .action-name {
                    color: white;
                }
            }
            transition: 0.3s;
            &:hover {
                background-color: $color-dark-purple;
                transition: 0.3s;
                .action-name {
                    color: white;
                }
            }
            &.reply {
                &:hover {
                    .action-icon {
                        color: white !important;
                    }
                    .action-name {
                        color: white !important;
                    }
                }
            }
        }
    }
}

.new-post {
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
    cursor: pointer;
    user-select: none;
    border-radius: 20px;
    color: white;
    background: #412A92;
    z-index: 100;
    position: fixed;
    top: 90px;
    left: 50%;
    transform: translate(-20%, 0);
    border: 2px solid white;
    font-weight: bold;
    span {
        margin: auto 10px auto 0;
    }
    &:hover {
        background-color: $color-dark-purple;
        transition: 0.3s;
    }
}