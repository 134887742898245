
.headerPic {
  img{
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  object-fit: cover;
  padding: 1px;
  }
}
