button {
    box-shadow: none;
}

.buttonContainer {
    display: flex;
    align-items: center;
}

.selectMenu input:focus{
    box-shadow: none;
}

/* .ant-select-selector:hover, .ant-select-selector:focus-within {
    border-color:#47317D !important;
    box-shadow: none !important;
} */

/* .ant-btn:hover {
    background-color: white;
    color: #47317D;
    border-color: #47317D;
} */

/* .ant-btn-primary {
    background: white;
    color: black;
    border-color: black;
} */

/* .ant-pagination-prev:hover {
    border-color: #47317D;
}

.ant-pagination-next:hover {
    border-color: #47317D;
}

.ant-pagination-item:hover {
    border-color: #47317D;
}

.ant-pagination-item-active {
    border-color: #47317D;
} */