@media (min-width: 768px) {
.wordCloud {
    border-radius: 10px;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    // font-size: 1vw;
    background:#47317D;
    background-repeat: round;
    // background-size: 100%;
  
    section {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background: white;
      color: black;
      width: 100%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      height: 100%;
    }
   
  
  
  }
  .wordDescription{
    width: 35%;
    p {
      margin-left: 5%;
      margin-right: 5%;
      height: 100%;
      width:90%;
      font-size: 1.5vw;
    }
  }
  .wordDiv{
    width: 70%;
    border-left: 1px solid black;
    justify-content: center;
    align-items: center;
    
  }
  .title {
    font-size: 3.7vw;
    text-shadow: black 2px 2px 5px;
    span {
      padding: 5px;
    }
  }
}
@media (max-width: 768px) {
  .wordCloud {
      border-radius: 10px;
      margin-top: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      // font-size: 1vw;
      background:#47317D;
      background-repeat: round;
      // background-size: 100%;
    
      section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        background: white;
        color: black;
        width: 100%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        height: 100%;
      }
   
    
    
    }
    .wordDescription{
      
      width: 100%;
      border-top: 1px solid black;
      // font-size: 1.5vw;
      p{
        margin-top: 3%;
      }

    }
    .wordDiv{
      width: 50%;
      canvas{
        margin-top: 3%;
        margin-bottom: 3%;
      }
    }
    .title {
      font-size: 3.7vw;
      text-shadow: black 2px 2px 5px;
      span {
        padding: 5px;
      }
    }
  }
