.colabTraits {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #47317D;
  background-repeat: round;
 
  text-align: center;
  border-radius: 10px;
  color: white;
  

}


.colabTraits canvas {
  color: white;
  outline: none;
}

.traitsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  max-width: 100%;
  color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0 auto;
  padding: 2% 10% 0 10%;
 
}

.traits-container {
  margin-top: 1%;
  position: relative;

  text-align: center;
 
  max-width: 100%;
  max-height: 100%;
  min-width: 80%;
  margin-bottom: 10px;
  border-radius: 10px;
}



/* When the screen width of the user is 768 pixels or less the properties here are applied... the user is probably on a phone if the width is this small*/
@media (max-width: 768px) {
  /* Lets change the font size of the "Collaborative Traits" title */

  div.title{
    font-size: 3.5vw;
  }
  

  Button.reset-traits-btn{
    margin-top: 3px;
    width: 8vw;
    height: 4.75vw;
    font-size: 1vw;
    padding: 3px;
  }


  .mentor, .empath, .leader, .entrepreneurial, .risk-taker{
    font-size: 1.5vw;
  }
  i.Recommendations, span.Powered{
    font-size: 0.75vw;
  }

}

@media (max-width: 1140px) {
  /* Customization for people with screen width of 1140px or less */

  Button.reset-traits-btn{
    margin-top: 3px;
    width: 12vw;
    height: 5vw;
    font-size: 1vw;
    padding: 1px;
  }
  div.title{
    font-size: 4vw;
  }
  i.Recommendations, span.Powered{
    font-size: 1vw;
  }
  .mentor, .empath, .leader, .entrepreneurial, .risk-taker{
    font-size: 1.75vw;
  }


  .mentor{
    top: 15.3%;
    left: 12%;
  
  }

}

  .traits-container .labels {
    right: -90%;
  }


.mentor {
  z-index: 23;
  position: absolute;
  transform: rotate(-37deg);
  color: #f1c40f;
  top:9%;
  left:9%;
}
.empath {
  z-index: 23;
  transform: rotate(109deg);
  position: absolute;
  color: #27ae60;
  top: 61%;
  left:83%;
}
.leader {
  z-index: 23;
  transform: rotate(39deg);
  position: absolute;
  color: #3498db;
  top: 9%;
  left: 68%;
}
.entrepreneurial {
  z-index: 23;
  transform: rotate(-106deg);
  position: absolute;
  color: #8e44ad;
  top: 61%;
  left: -16%;
}
.risk-taker {
  z-index: 23;
 
  position: absolute;
  color: #e74c3c;
  top: 95%;
  left: 36.5%;
  transform: rotate(1deg);
}

.labels{
  
  font-size: 2vw;
  font-weight: bold;
}

.footer-items{
  display: flex;
  flex-direction: column;
  align-items: center;
  Button{
    margin-bottom:10px;
  }
  Button:hover{
    background-color: #6A44B6;
    color: white;
  }

}