@import '../../styles/globals';

.MyContent{
  max-width: 90vw;
  margin: auto;

  .top-controller {
    padding-top: 0px !important;
  }

  .jumbotron{
    text-align: center;

    p {
      margin-bottom: 0.5rem;
    }

    .attributeHeading {
      color: $color-purple;
      text-transform: uppercase;
      font-size: 2.5rem;
    }
  
    .kredSub{
      margin-top: -0.5rem;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  @media (min-width: 320px) and (max-width: 768px) {
    .attributeHeading {
      font-size: 1.5rem !important;
    }
  }
}