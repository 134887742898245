@import '../../styles/globals';

@media (max-width: 768px) {
    .groupChatPage {
        width: 100%;
        background-color: #151c37;
        display: flex;
        flex-direction: column;
        h1 {
            color: white;
            // font-size: 3vw;
            font-size: 1.7rem;
        }
    }
  }
  
  @media (min-width: 768px) {
    .groupChatPage {
        background-color: #151c37;
        display: flex;
        flex-direction: column;
    
        width: 83%;
        h1 {
            color: white;
            // font-size: 3vw;
                font-size: 2.7rem;
        }
    }
}

.groupChatBox {
  padding-left: 0;
  padding-right: 0;
}

.page-container {
    display: flex;
    flex-direction: row;
    .group-details {
        min-width: 300px;
        width: 300px;
        position: fixed;
        height: calc(100% - 76px);
        top: 76px;
        background-color: #47317d;
        .group-detail {
            display: flex;
            flex-direction: column;
            height: 100%;
            .group-title {
                font-size: 1.4rem;
                padding: 10px 0;
                color: white;
                text-align: center;
                font-weight: bold;
            }
            .group-tag {
                font-size: .9rem;
                padding: 10px 0;
                color: white;
                text-align: center;
            }
            .groupImage {
                margin: 0;
                opacity: 0.8;
                width: 100%;
                height: 180px;
            }
        }
        .members-list {
            margin: 0 10px 10px 10px;
            background-color: white;
            flex-grow: 1;
            border-radius: 5px;
            overflow-y: auto;
            .member-box {
                padding: 10px;
                border-bottom: 1px solid $color-lightest-purple;
                display: flex;
                flex-direction: row;
                .member_image {
                    margin: 5px 0;
                    width: 56px;
                    height: 56px;
                    border-radius: 100%;
                }
                .member-detail {
                    margin-left: 10px;
                    .member-name {
                        font-size: 14px;
                        font-weight: bold;
                        color: black;
                        cursor: pointer;
                        user-select: none;
                        text-decoration: underline;
                    }
                    .member-role {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .group-chat {
        min-height: calc(100vh - 76px);
        width: 100%;
        padding: 2rem 0rem;
        padding-left: 300px;
        margin: 0 auto;
        max-width: 1000px;
        .postBox {
            background-color: white;
            border-radius: 10px;
            padding: 10px 20px;
            margin-bottom: 20px;
            .post-action {
                display: flex;
                flex-direction: row;
                .profile-image {
                    width: 56px;
                    height: 56px;
                    border-radius: 100%;
                }
                .profile-detail {
                    margin-left: 10px;
                    .profile-name {
                        font-size: 14px;
                        font-weight: bold;
                        color: black;
                        cursor: pointer;
                        user-select: none;
                        text-decoration: underline;
                    }
                    .profile-role {
                        font-size: 12px;
                    }
                }
                .start-post {
                    background-color: $color-light-purple;
                    color: white;
                    margin: 0 0 auto 20px;
                    flex-grow: 1;
                    text-align: center;
                    padding: 10px;
                    border-radius: 30px;
                    cursor: pointer;
                    user-select: none;
                    transition: 0.3s;
                    &:hover {
                        background-color: $color-dark-purple;
                        transition: 0.3s;
                    }
                }
            }
            .post-box-details {
                padding: 15px 0;
                border-radius: 5px;
                border-top-left-radius: 0;;
                .post-box-footer {
                    margin-top: 15px;
                    display: flex;
                    flex-direction: row;
                    .add-image {
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        background-color: $color-light-purple;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        color: white;
                        cursor: pointer;
                        user-select: none;
                        transition: 0.3s;
                        &:hover {
                            background-color: $color-dark-purple;
                            transition: 0.3s;
                        }
                        span {
                            margin: auto;
                        }
                    }
                    .post-button {
                        margin-left: auto;
                        background-color: $color-light-purple;
                        color: white;
                        text-align: center;
                        padding: 10px 30px;
                        border-radius: 30px;
                        cursor: pointer;
                        user-select: none;
                        transition: 0.3s;
                        &:hover {
                            background-color: $color-dark-purple;
                            transition: 0.3s;
                        }
                        &.disabled {
                            cursor: not-allowed;
                            background-color: $color-disabled-text;
                        }
                    }
                }
            }
        }
        .post-list {
            width: 100%;
        }
    }
}

.images-to-post {
    // margin-bottom: 10px;
    background-color: black;
    height: 300px;
    position: relative;
    .carousel-item {
        img {
            margin-top: 10px;
            height: 280px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.deselect-image {
    color: white;
    position: absolute;
    bottom: 0px;
    left: 10px;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}