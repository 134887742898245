.buttonContainer {
    display: flex;
    align-items: center;
}

/* .tabContainer {
    display: flex;
    align-items: center;
} */

.fixed-component.fixed {
    position: fixed;
    top: 70px;
    background-color: rgb(255, 255, 255);
    z-index: 10;
}