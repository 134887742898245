/* * {
    margin: 0;
    padding: 0;
}

.sortbar {
    width: 220px;
    height: 25px;
    border: solid;
    position: relative;
    font-size: small;
}

.sortbar .dropdown-btn {
    border-radius: 2px;
    background-color: #fff;
    color: black;
    box-shadow: 3px, 3px, 10px, 6px, rgba(0, 0, 0, 0.06);
    cursor: pointer;
}

.sortbar .dropdown-content {
    position: absolute;
    top: 115%;
    left: 0;
    background-color: #fff;
    color: black;
    border: solid;
    width: 100%;
}

.sortbar .dropdown-content .dropdown-item {
    color: black;
    cursor: pointer;
    transform: all 0.2s;
}

.sortbar .dropdown-content .dropdown-item:hover {
    background-color: #f4f4f4;
} */

.sortDropdown-btn.dropdown-toggle.btn.btn-primary {
    background-color: #47317D;
    margin-left: 1px;
    margin-bottom: 12px;
}

.sortDropdown-btn.dropdown-toggle.btn.btn-primary:hover {
    background-color: #6A44B6;
}

.sortDropdown-btn.dropdown-toggle.btn.btn-primary:focus {
    box-shadow: none;
}

.sortDropdown-btn.dropdown-toggle.btn.btn-primary:active:focus {
    box-shadow: none;
}

.sortDropdown-menu.dropdown-menu {
    font-size: medium;
    min-width: auto;
    width: auto;
    right: 0px !important;
    left: 0px !important;
}

.sortDropdown-menu .dropdown-item {
    font-size: medium;
    padding-left: 0;
    padding-right: 0;
    user-select: none;
}

.sortDropdown-btn.btn-primary {
    border: none;
}

@media (max-width: 1200px) {
    .sortDropdown-menu.dropdown-menu {
        width: 273px;
    }
}