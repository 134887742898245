@import '../../../styles/globals';

@media (min-width: 576px) {
  .modal-sm {
    max-width: 350px !important;
  }
}

.home-page{
  padding-bottom: 80px;

  

  .header{
    padding: 0px 10px;
    margin-bottom: 2rem;
  }

  .headerTitle{
    color: $color-purple;
    font-weight: 800;
    font-size: 35px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .headerSub{
    text-align: left;
    font-size: 18px;
    text-align: justify;
    text-align-last: center;
    width: 80%;
    margin: auto;
  }

  .createBtnHeader{
    height: 50px;
    width: 260px;
    border-radius: 100px;
    border: none;
    font-weight: 700;
    font-size: 18px;
    background-color: white !important;
    color: $color-purple !important;
    transition: 0.15s ease-in-out !important;
    transition-property: transform;
    box-shadow: $standard-box-shadow;
  }

  .createBtnHeader:active {
    background-color: darken($color-white, 5%);
    box-shadow: 0 0 0 0 rgba(0,0,0,0.5) !important;
  }

  .cardColumns{
    margin-bottom: 20px;
  }

  .cardColumns:nth-of-type(3),.cardColumns:nth-of-type(4){
    display: none;
  }

  .createBtnBottom{
    margin-top: 10px;
    height: 50px;
    width: 260px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 18px;
  }

  .show {
    display: initial;
  }

  .video-container {
    min-width: 200px;
    max-width: 550px;
    margin: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    .title {
     text-align: center;
    }
  }

  .responsive-square{
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;

    .responsive-square-inner{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .fa-play {
    font-size: 4rem;
    position: absolute;
    color: $color-white;
    margin: auto calc(50% - 50px);
    background-color: $color-action-button;
    border-radius: 100px;
    padding: 20px 15px 15px 25px;
  }

  .start-arrow {
    opacity: 0;
    transition: 0.2s ease-in-out !important;
  }

  .createBtnText {
    margin-left: 12px;
  }

  .createBtnHeader:hover > .start-arrow {
    transition: 0.2s ease-in-out !important;
    margin-left: 6px;
    opacity: 1;
  }

  .btnHeaderContainer{
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fullBtn{
    margin-top: 10px;
    height: 37px;
    width: 156px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 14px;
  }

  .profile-pic {
    object-fit: cover;
    width: 140px;
    height: 140px;
  }
  
  .profile-pic-container {
    background-color: black;
    min-width: 140px;
    min-height: 140px;
    max-width: 140px;
    max-height: 140px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    top: -70px;
    margin: auto;
  }

  .keeperProfileExamples{
    border-radius: 15px;
    // border-top: 15px solid $color-purple;
    padding: 0px 50px;
  }
  .name{
    position: relative;
    top: -50px;
    font-size: 22px;
    font-weight: 600;
  }
  .attributeImage {
    height: 326px;
    position: relative;
    background-color: $color-black;
  }
  .attributeHeading {
    font-size: 18px;
    font-weight: 600;
  }
  .attributeHeader{
    min-height: 3rem;
  }
  .attributeText {
    overflow-wrap: break-word;
    padding-right: 20px;
    padding-left: 20px;
    min-height: 120px;
  }
  .cardBox{
    background-color: #F7F7F7;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  

  .video-info{
    background-color: $color-purple;
    padding: 50px;
    margin-left: calc(50% - 50vw);
    width: 100vw;
  }

  .svgTag{
    width: 100vw;
    margin-left: calc(50% - 50vw);
    position: relative;
    top: -10px;
    background-color: $color-purple;
    border: none;
    stroke: none;
    height: 50px;
  }
  .svgCurve{
    fill: whitesmoke;
  }

  .videoHeaderContainer{
    max-width: 1280px;
    margin: auto !important;
  }

  .videoHeader{
    color:white;
    margin-bottom: 10px;
    line-height: 90%;
    font-weight: 800;

  }

  .videoHeaderSub{
    color:white;
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 300;
    width:70%;
    line-height: 1.2;
    text-align: left;
  }

  .homeContentTop{
    overflow: hidden;
  }
  
  .space-holder {
    height: 600px;
  }

  .slick-prev, .slick-next {
    z-index: 2;
    font-size: 50px;
    display: none !important;
  }

  .slick-dots{
    margin-bottom: 60px;
  }
  .slick-dots > li > button:before{
    font-size: 13px;
  }
  .slick-dots > li.slick-active > button:before{
    color:$color-purple;
  }

  .slick-list {
    overflow: unset;
  }
  .slick-slide {
    opacity: 0;
    transition: opacity .2s ease-out;
  }
  .slick-active, .slick-current {
    opacity: 1;
  }
  @media (min-width: 1001px) {
    .videoHeader{
      font-size: 5vw;
      line-height: 90%;
    }
    .videoHeaderSub{
      font-size: 28px;
      width:70%;
      line-height: 1.2;
    }
    .svgTag{
      height: 35px;
    }
    .createBtnBottom, .createBtnHeader{
      height: 44px;
      width: 260px;
      font-weight: 700;
      font-size: 16px;
    }
    .createBtnHeader:hover {
      width: 280px;
    }
  }
  @media (min-width: 1800px){
    .videoHeader{
      font-size: 6rem;
    }
  }
  @media (max-width: 1000px) {
    .slick-prev, .slick-next {
      display: none !important;
    }
    .videoHeader{
      font-size: 50px;
      line-height: 90%;
    }
    .videoHeaderSub{
      font-size: 20px;
      width:70%;
      line-height: 1;
    }
    .svgTag{
      height: 30px;
    }
    .createBtnBottom, .createBtnHeader{
      height: 40px;
      font-weight: 700;
      font-size: 16px;
    }
    .createBtnHeader:hover {
      width: 270px;
    }
  }

  @media (max-width: 536px) { 
    .videoHeader {
      line-height: 100% !important;
      margin-bottom: 0px !important;
    }
  }

  @media (max-width: 768px) {
    .videoHeader, .videoHeaderSub, .btnHeaderContainer{
      text-align: center;
    }

    .btnHeaderContainer{
      margin-bottom: 30px;
    }

    .videoHeaderSub{
      font-size: 20px;
      width:100%;
      line-height: 1;
    }
    .svgTag{
      height: 25px;
    }
    .createBtnBottom, .createBtnHeader{
      height: 40px;
      width: 240px;
      font-weight: 700;
      font-size: 16px;
    }
    .createBtnHeader:hover {
      width: 260px;
    }
  }

  @media (min-width: 320px) and (max-width: 550px) {
    .fa-play {
      font-size: 1.75rem;
      margin: auto calc(50% - 30px);
      padding: 15px 12px 12px 20px;
    }
  }

  @media (min-width: 609px) and (max-width: 1000px) {
    .fa-play {
      font-size: 5vw;
      margin: auto calc(50% - 40px);
    }
  }

  @media (max-width: 550px) {
    .video-info{
      background-color: $color-purple;
      padding: 50px 20px;
      width: 100vw;
      margin-left: calc(50% - 50vw);
    }
    .keeperProfileExamples{
      padding: 0px 10px 10px 10px;
    }
    .videoHeader{
      font-size: 34px;
      line-height: 50%;
      margin-bottom: 15px;
    }
    .videoHeaderSub{
      font-size: 18px;
      line-height: 1;
    }
    .cardBox{
      box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    }
    .svgTag{
      height: 20px;
    }
    .createBtnBottom, .createBtnHeader{
      height: 40px;
      width: 220px;
      font-weight: 700;
      font-size: 14px;
    }
    .createBtnHeader:hover {
      width: 240px;
    }
    .headerTitle{
      line-height: 120%;
    }
  }
}