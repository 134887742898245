@import '../../styles/globals';

.controllers{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;

  .backBtnArrowBack {
    position: relative;
    left: -20px;
  }
  
  .arrowNext {
    position: relative;
    right: -20px;
  }
}