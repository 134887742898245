.affinityGroups {
  border-radius: 10px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
  color: white;
  background:#47317D;
  background-repeat: round;
  
  // background-size: 100%;
  .title {
    span {
      // font-size: unset;
      text-shadow: black 2px 2px 5px;
    }
  }
  @media (max-width: 600px) {
    .title {
      span {
        // font-size: 2em;
        margin: 5px 0px;
    }}
    }
  section {
    display: flex;
    align-items: center;
    // justify-content: center;

    background: white;
    color: black;
    font-size: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    width: 100%;
    min-height: fit-content;
    max-height: max-content;
    text-align: center;
  }
  p {
    width: 300px;
    border-right: 1px solid black;
    padding: 10px;
  }

  .group1 {
    position: relative;
    width: 100%;

    span {
      margin: 0%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: auto;
      // font-size: 2vw;
      font-size: 1.3rem;
      font-weight: 800;
      background-color: rgba(255, 255, 255, 0.589);
      color: #47317d;
      text-shadow: #47317d 2px 2px 5px;
    }
  }

  img {
    height: 10rem;
    width: 90%;
    border-radius: 15px;
  }
}

.cursor-pointer{
  cursor: pointer;
}
.empty{

    display: flex;
    align-items: center;
    justify-content: center;

    background: white;
    color: black;
    font-size: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    width: 100%;
    min-height: fit-content;
    max-height: max-content;
    text-align: center;
  
button {
  height: 48px;
  background-color: #47317D;
  color: white;
  // font-weight: bold;
  // font-size: 18px;
  // line-height: 23px;
  font-family: 'Montserrat', sans-serif;
  padding-right: 30px;
  padding-left: 30px;
  border-width: 0;

}

}