.companyPage {
  width: 83%;
  height: 100%;
  background-color: #151c37;
  display: flex;
  flex-direction: column;
  padding: 2%;
  h1 {
    color: white;
    font-size: 3vw;
  }
}

.company {
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
  color: white;
  background-color: #47317d;
  h3 {
    padding: 2%;
  }

  section {
    // display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: black;
    font-size: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 40px;
    width: 100%;
    object-fit: contain;
    padding-top: 112px;
  }
}

// .profileGrid {
//   display: grid;
//   grid-template-columns: repeat(3, auto);
//   gap: 60px;
//   overflow: hidden;

// }

.companyPageProfileGrid{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profileBlock {
  cursor: pointer;
  margin-bottom: 77px;
  margin-right: 20px;
  margin-left: 20px;
  max-width: 246px;
  max-height: 265px;
}
.imageBlock {
  margin-right: 30px;
  margin-left: 30px;
  width: 186px;
  height: 186px;
  margin-bottom: 14px;
  
  overflow: hidden;
  
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
  img {
    // object-fit: cover;
    // width: 186px; /* width of container */
    // height: 186px; /* height of container */
    object-fit: cover;
    object-position:20% 50%;
    border-radius: 100%;
    
    
  }
}

.textBlock {
  text-align: center ;
  .profileName{
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
  }
  .profileRole {
    font-weight: 700;
    font-size: 15px;
    line-height: 22.95px;
    font-family: Arial, Helvetica, sans-serif;
  }
}
.title {
  font-size: 2.7em;
  text-shadow: black 2px 2px 5px;
  span {
    padding: 5px;
  }
}
@media (max-width: 600px) {
  .title {
      font-size: 2.4em;
  }
  }

.linkBlock {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}
.buttonWrapper {
  margin: 20px;
}
.button {
  height: 48px;
  background-color: #47317D;
  color: white;
  // font-weight: bold;
  // font-size: 18px;
  // line-height: 23px;
  font-family: 'Montserrat', sans-serif;
  padding-right: 30px;
  padding-left: 30px;
  border-width: 0;

}

@media (min-width: 992px)
{
.companyPage {
    width: 83%;
}
}

@media (min-width: 768px)
{
.companyPage {
    width: 83%;
}
}


@media (max-width: 600px)
{
.companyPage {
    width: 100%;
}
}

.bottom-button {
 // background-color: #272d46;
  }
  
  .btn-right-corner-innovation{
  justify-content: flex-end;
      display: flex;
      padding-right:15px !important;
    }

    
@media (max-width: 600px)
{
.btn-right-corner-innovation {
    justify-content: unset; 
     display: unset; 
     padding-right: 15px !important;
}
}