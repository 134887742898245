@import '../../styles/globals';


.avatar-upload-container {
  max-width: 960px;
  margin: auto;
  .avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 50px auto;
  
    .avatar-edit {
      position: absolute;
      right: 45px;
      z-index: 1;
      top: 115px;
  
      input {
        display: none;
  
        + label {
            display: inline-block;
            width: 34px;
            height: 34px;
            margin-bottom: 0;
            border-radius: 100%;
            background: rgba($color-purple, 0.75);
            border: 1px solid transparent;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
            cursor: pointer;
            font-weight: normal;
            transition: all .2s ease-in-out;
  
            &:hover {
              background: lighten($color-purple, 5%);
              border: none;
            }
  
            &:after {
              font-family: 'FontAwesome';
              color: $color-white;
              position: absolute;
              top: 5.25px;
              left: 2px;
              right: 0;
              text-align: center;
              margin: auto;
            }
  
            &.add:after {
              content: "\f067";
            }
  
            &.edit:after {
              content: "\f040";
            }
          }
        }
      }
  
    .avatar-preview {
      width: 192px;
      height: 192px;
      position: relative;
      border-radius: 100%;
      border: 6px solid $color-purple;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin: auto;
  
      .imagePreview {
        width: 100%;
        border-radius: 100px;
      }
  
      > div {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  
    .delete-btn {
      position: absolute;
      right: 8px;
      top: 8px;
      width: 24px;
      height: 24px;
      margin-bottom: 0;
      border-radius: 100%;
      background: $color-purple;
      color: $color-white;
      border: 1px solid transparent;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      cursor: pointer;
      transition: all .2s ease-in-out;
  
      &:hover {
        background: lighten($color-purple, 10%);
      }
    }
  }
}
