.searchMulti {
    background-color: white;
    display: flex;
    border: solid;
    border-radius: 5px;
    /* height: 20px; */
    margin-left: 5px;
    margin-bottom: 12px;
}

.searchInputs {
    display: flex;
}

.searchMulti input {
    box-sizing: border-box;
    background-color: white;
    border: 0;
    font-size: 18px;
    padding: 5px;
    height: 35px;
    width: 200px;
    color: black;
}

.searchMulti input:focus {
    box-shadow: none;
}

.datePicker{
    z-index: 2;
}

.searchIcon {
    height: 35px;
    background-color: white;
    color: black;
    border: 0;
    display: grid;
    place-items: center;
    margin-left: 5px;
}

.closeIcon {
    height: 35px;
    background-color: white;
    color: black;
    border: 0;
    display: grid;
    place-items: center;
    margin-right: 5px;
}

.clearBtn {
    cursor: pointer;
}

.searchBtn {
    cursor: pointer;
}

.searchIcon svg {
    font-size: 30px;
}

.selectMenu {
    border: none;
}

/* .dropdown-toggle {
    background-color: #47317D;
    background-color: #272d46;
} */

.multiDropdown-btn.dropdown-toggle.btn.btn-primary {
    background-color: #47317D;
    margin-left: 1px;
    margin-bottom: 12px;
}

.multiDropdown-btn.dropdown-toggle.btn.btn-primary:hover {
    background-color: #6A44B6;
}

.multiDropdown-btn.dropdown-toggle.btn.btn-primary:focus {
    box-shadow: none;
}

.multiDropdown-btn.dropdown-toggle.btn.btn-primary:active:focus {
    box-shadow: none;
}

.multiDropdown-menu.dropdown-menu {
    font-size: medium;
    min-width: auto;
    width: auto;
    right: unset !important;
    left: unset !important;
}

.multiDropdown-menu .dropdown-item {
    font-size: medium;
    padding-left: 15px;
    padding-right: 15px;
    user-select: none;
}

.multiDropdown-menu .dropdown-item .active {
    background-color: #6A44B6;
}

.multiDropdown-btn.btn-primary {
    border: none;
}

@media (max-width: 1200px) {
    .searchMulti {
        width: 265px;
    }
}

@media (max-width: 768px) {
    .searchMulti input {
      text-align: start;
    }
  }