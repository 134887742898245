@import '../../styles/globals';

.admin-form {
  h3{
    margin-top: 3rem;
  }
  
  button{
    margin-top: 1rem;
  }
  
  label.form-label{
    margin: 1rem 0 0 0;
  }
}