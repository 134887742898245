@import './globals';

.search{
  * {
    // outline: 1px dotted red !important;
  }
  
  .linkContents{
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: auto;
    padding-bottom: auto;
    border-radius: 8px!important;

    .icon{
      position: relative;
      margin-right: 10px;
      top: inherit;
      left: inherit;
    }
  }

  .form-control {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .copy-btn {
    background-color: $color-purple;
    color: $color-white;
    cursor: pointer;
  }

  .copy-btn:hover {
    font-weight: 600;
  }
  
  .copyButton{
    height: 30px;
    border-radius: 8px;
    border: 1px black;
    background-color: rgb(216, 216, 216);
    padding-top: auto;
    padding-bottom: auto;
    padding-left: 4px;
    padding-right: 4px;
  }
  .copyButton:hover{
    background-color: grey;
  }
  .name {
    font-weight: bold;
  }
  .url{
    margin:0px!important;
    width: 100%;
  }
  .basicInfo{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .email{
    margin-left: 5vw;
  }
  .profile-pic{
    width: 10vw;
    height: 10vw;
    max-width: 150px;
    max-height: 150px;
    margin: 1vw;
  }
  .email-error {
    border-color: red!important;
  }
}
