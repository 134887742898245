@import './globals';

.complete-container {
  text-align: center;

  .btn-container {
    display: flex;
    text-align: center;
    margin: 30px auto;
    width: 96%;
  }

  .congrats-container {
    margin-top: -50px;
    z-index: 100;
  }

  .gifContainer{
    margin: -30px auto 0px;
    max-width: 500px;
  }

  img{
    height: auto;
    max-width: 100%;
  }

  .subtitle{
    color: $color-purple;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.25;
  }

  .hero.is-small{
    padding-top: 1.5rem;
  }

  .button {
    margin-bottom: 1rem;
  }

  .sub-text {
    margin-bottom: 30px;
  }

  @media (min-width: 992px) {
    .profile-pic-container {
      width: 200px;
      height: 200px;
      margin: 30px auto 0px;
    }
  }

  @media (min-width: 320px) and (max-width: 768px) {
    .subtitle{
      font-size: 1.5rem;
      line-height: 1.25;
    }
  }
}